/* eslint-disable max-statements */
import React, { createContext, useContext, useState, ReactNode } from "react"
import SnapAnimation from "./SnapAnimation"
import UnsnapAnimation from "./UnsnapAnimation"
import { useComponentRegistry }
    from "../../../../../../../../providers/multiselectProvider/useComponentMethods"
import { Mesh, Quaternion, Vector3 } from "three"

interface AnimationInstance {
    type: "snap" | "unsnap";
    position: Vector3;
    rotation: Quaternion;
    color: string;
}

interface AnimationContextType {
    isAnimating: boolean;
    setIsAnimating: (value: boolean) => void;
    triggerAnimation: (
        type: string,
        partId?: string,
        markerName?: string,
        color?: string // Added color prop
    ) => void;
}

const AnimationContext = createContext<AnimationContextType | undefined>(undefined)

export const GlobalAnimationProvider: React.FC<{ children: ReactNode, }> = ({ children, }) => {
    const [isAnimating, setIsAnimating,] = useState(false)
    const [animations, setAnimations,] = useState<AnimationInstance[]>([])
    const { getComponent, } = useComponentRegistry()

    const triggerAnimation
        = (
            type: string,
            partId?: string,
            markerName?: string,
            color = "green" // Added color parameter with default value
        ) => {
            if (!partId) { return }
            console.log("triggering animation", type)
            setIsAnimating(true)
            const part = getComponent(partId)
            const markers = part?.getAllMarkers()
            if (!markers) { return }

            const marker = markers.find((marker: Mesh) => marker.name === markerName)
            if (part && marker) {
                const position = marker.getWorldPosition(new Vector3())
                const rotation = marker.getWorldQuaternion(new Quaternion())

                setAnimations(prevAnimations => [
                    ...prevAnimations,
                    { type: type as "snap" | "unsnap", position, rotation, color, },
                ])

                setTimeout(() => {
                    setAnimations(prevAnimations =>
                        prevAnimations.filter(anim =>
                            !(anim.position.equals(position) && anim.rotation.equals(rotation))
                        )
                    )
                    if (animations.length === 1) {
                        setIsAnimating(false)
                    }
                }, 1000) // Example timeout
            }
        }

    return (
        <AnimationContext.Provider value={{ isAnimating, setIsAnimating, triggerAnimation, }}>
            {children}
            {animations.map((anim, index) => (
                <React.Fragment key={index}>
                    {anim.type === "snap" && (
                        <SnapAnimation
                            position={anim.position}
                            rotation={anim.rotation}
                            color={anim.color}
                        />
                    )}
                    {anim.type === "unsnap" && (
                        <UnsnapAnimation
                            position={anim.position}
                            rotation={anim.rotation}
                            color={anim.color}
                        />
                    )}
                </React.Fragment>
            ))}
        </AnimationContext.Provider>
    )
}

export const useGlobalAnimation = (): AnimationContextType => {
    const context = useContext(AnimationContext)
    if (context === undefined) {
        throw new Error("useGlobalAnimation must be used within a GlobalAnimationProvider")
    }
    return context
}

