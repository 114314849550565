/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { MutableRefObject } from "react"
import { RecoilState, useRecoilValue } from "recoil"
import { allConnections } from "../../../../../../../../state/scene/selectors"
import { ConnectionOfPart } from "../../../../../../../../state/scene/types"
import { getSideAndPosition } from "../../../../../../../../utils/MarkerUtil"
import { SegmentedTubeInfo, SegmentedTubeMarker } from "../types/types"
import NewPartButton from "./NewPartButton"
import segmentedTubeUtils from "../utils/SegmentedTubeUtils"
import { Group, Scene, Vector3 } from "three"
import { XYZ, XYZW } from "../../../../../../../../utils/Types"

type NewPartButtonsInfo = {
    partId: string,
    info: MutableRefObject<SegmentedTubeInfo>,
    length: number,
    partConnectionsValue: ConnectionOfPart[],
    rotation: XYZW,
    position: XYZ,
    setIsSelected: (selected: boolean) => void,
    scene?: Scene,
    isItemSelected?: (param: string) => RecoilState<boolean>,
}

const BATCH_SIZE = 1 // Adjust this value based on performance

const NewPartButtonsGroup = (props: NewPartButtonsInfo) => {
    const connections = useRecoilValue(allConnections)
    const [renderedCount, setRenderedCount,] = useState(0)
    const [markers, setMarkers,] = useState<(SegmentedTubeMarker & { isMiddleSection: boolean, })[]>([])

  useEffect(() => {
    if (renderedCount < markers.length) {
      const timeoutId = setTimeout(() => {
        setRenderedCount(prev => Math.min(prev + BATCH_SIZE, markers.length))
      }, 0)
      return () => clearTimeout(timeoutId)
    }
  }, [renderedCount, markers.length,])

    const getMarkers = () => {
        const markers: (SegmentedTubeMarker & { isMiddleSection: boolean, })[] = []

        Object.keys(props.info.current.startSection).forEach(key => {
            if (props.partConnectionsValue.every(c => {
                const { markerSide: markerNumber, } = getSideAndPosition(c.partMarkerName)
                const firstKey = Object.keys(props.info.current.startSection)[0]
                if (props.info.current.startSection[firstKey] && "mesh" in props.info.current.startSection[firstKey]) {
                    return true
                } else {
                    return markerNumber !== key
                }
            })) {
                markers.push({ ...props.info.current.startSection[key], isMiddleSection: false, })
            }
        })

        Object.keys(props.info.current.endSection).forEach(key => {
            if (props.partConnectionsValue.every(c => {
                const { markerSide: markerNumber, } = getSideAndPosition(c.partMarkerName)
                const firstKey = Object.keys(props.info.current.endSection)[0]
                if (props.info.current.endSection[firstKey] && "mesh" in props.info.current.endSection[firstKey]) {
                    return true
                } else {
                    return markerNumber !== key
                }
            })) {
                markers.push({ ...props.info.current.endSection[key], isMiddleSection: false, })
            }
        })

        if (Object.keys(props.info.current.middleSection).length) {
            const markerByFreePositions = segmentedTubeUtils.getMarkerByFreePositions(
                props.partId,
                connections,
                props.info.current.middleSection,
                props.length
            )

            Object.keys(props.info.current.middleSection).forEach(key => {
                const section = props.info.current.middleSection[key]
                markers.push({ ...section[markerByFreePositions], isMiddleSection: true, })
            })
        }
        return markers
    }
    useEffect(() => {
        setMarkers(getMarkers())
      }, [getMarkers,])

    useEffect(() => {
        if (renderedCount < markers.length) {
          const timeoutId = setTimeout(() => {
            setRenderedCount(prev => Math.min(prev + BATCH_SIZE, markers.length))
          }, 0)
          return () => clearTimeout(timeoutId)
        }
      }, [renderedCount, markers.length,])

    return (
        <>
          {markers.slice(0, renderedCount).map(marker =>
            marker && marker.outer && (
              <NewPartButton
                partId={props.partId}
                info={props.info}
                marker={marker}
                key={marker.outer.name}
                isMiddleSection={marker.isMiddleSection}
                setIsSelected={props.setIsSelected}
                scene={props.scene}
                partConnectionsValue={props.partConnectionsValue}
              />
            )
          )}
        </>
      )
    }

export default NewPartButtonsGroup