/* eslint-disable max-statements */
/* eslint-disable no-case-declarations */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* eslint-disable max-lines */
import React from "react"
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react"
import { useThree } from "@react-three/fiber"
import { useRecoilValue } from "recoil"
import { Box3, MathUtils, Mesh, Object3D } from "three"
import { SegmentedTubeSectionType } from "../../../../../../../../../common/api/Types"
import { connectionTypesSelector } from "../../../../../../../../state/initialDataSelectors"
import { allConnections, partConnections } from "../../../../../../../../state/scene/selectors"
import { useMultipleUpdates } from "../../../../../../../../state/scene/setters"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import {
    getMarkerRef,
    getPlaceholderIdWithoutPosition,
    getSideAndPosition,
    innerToOuter
} from "../../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import { isCollineal } from "../../../../../utils/utilsThree"
import { ROTATION_AXES_ENUM, SegmentedTubeInfo, SEGMENTED_TUBE_UI } from "../types/types"
import RotationAxesButtons from "../ui/RotationAxesButtons"
import SegmentedTubeUtils from "./SegmentedTubeUtils"
import useSegmentedTube from "./useSegmentedTube"
import { messageUtils } from
"../../../../../../../../components/main/DesignScreen/scene/LowerRightMessages"


type RotationUtilsPropsType = {
    tubeId: string,
    tubeInfoRef: MutableRefObject<SegmentedTubeInfo>,
    updateTransforms: () => void,
    setHideAddPartButtons: Dispatch<SetStateAction<boolean>>,
    tubeUI: SEGMENTED_TUBE_UI,
    setTubeUI: Dispatch<SetStateAction<SEGMENTED_TUBE_UI>>,
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    userRotation?: number,
    setUserRotationApplied: () => void,
    unsnap?: (id: string, markerName: string[]) => void,
    boundingBox?: Box3 | null,
    initialMarkerName: string,
}

export const useRotationUtils = ({
    tubeId,
    tubeInfoRef,
    updateTransforms,
    setHideAddPartButtons,
    tubeUI,
    setTubeUI,
    movableSection,
    userRotation,
    setUserRotationApplied,
    unsnap,
    boundingBox,
    initialMarkerName,
}: RotationUtilsPropsType) => {
    const { tube, updateTubeValues, } = useSegmentedTube(tubeId)
    const connectionTypes = useRecoilValue(connectionTypesSelector)
    const connections = useRecoilValue(allConnections)
    const multipleUpdate = useMultipleUpdates()
    const { scene, } = useThree()
    const partConnectionsValue = useRecoilValue(partConnections(tube.id))


    const rotationMarkerRef = useRef<Object3D | undefined>(undefined)
    const [actualRotation, setActualRotation,] = useState(userRotation || 0)
    const rotationAuxInfo = useRef<
        {
            lastRotationValue: number,
            checkGuidelines: boolean,
            tempConnections: PartConnectionType[],
        }>({
            lastRotationValue: 0,
            checkGuidelines: false,
            tempConnections: [],
        })

    const areOppositeMarkersCollineal = (markerNameA: string, markerNameB: string) => {
        const markerARef = getMarkerRef(scene, tube.id, markerNameA)
        const markerBRef = getMarkerRef(scene, tube.id, markerNameB)
        if (!markerARef || !markerBRef) {
            return false
        }
        const markerAPos = MeshUtils.copyWorldPosition(markerARef)
        const markerADir = MeshUtils.copyWorldDirection(markerARef)
        const markerBPos = MeshUtils.copyWorldPosition(markerBRef)
        const markerBDir = MeshUtils.copyWorldDirection(markerBRef)
        return isCollineal(markerADir, markerAPos, markerBDir, markerBPos)
    }

    const areMarkersOpposite = (markerNameA: string, markerNameB: string) => {
        const markerNameAWithoutPosition = getPlaceholderIdWithoutPosition(markerNameA)
        const markerNameBWithoutPosition = getPlaceholderIdWithoutPosition(markerNameB)

        const markerASection = tube.markers.find(marker =>
            innerToOuter(marker.name) === innerToOuter(markerNameAWithoutPosition))?.position
            ?? SegmentedTubeSectionType.MIDDLE

        const markerBSection = tube.markers.find(marker =>
            innerToOuter(marker.name) === innerToOuter(markerNameBWithoutPosition))?.position
            ?? SegmentedTubeSectionType.MIDDLE

        if ((markerASection === SegmentedTubeSectionType.START
            && markerBSection === SegmentedTubeSectionType.END)
            || (markerASection === SegmentedTubeSectionType.END
                && markerBSection === SegmentedTubeSectionType.START)
        ) {
            if (areOppositeMarkersCollineal(markerNameA, markerNameB)) {
                return true
            }
        } else if (markerASection === SegmentedTubeSectionType.MIDDLE
            && markerBSection === SegmentedTubeSectionType.MIDDLE
        ) {
            const markerAPosition = getSideAndPosition(markerNameA).markerPosition
            const markerBPosition = getSideAndPosition(markerNameB).markerPosition

            if (areOppositeMarkersCollineal(markerNameA, markerNameB)) {
                return true
            }
        }
        return false
    }

    //setting the rotation marker based on the attachment point and the part connections
    useEffect(() => {
        //console.log(partConnectionsValue, tubeId, "partConnectionsValue")
        if (tubeInfoRef.current.attachmentPoint) {
            if (partConnectionsValue.length === 0) {
                if (Object.keys(tubeInfoRef.current.startSection).length > 0) {
                    setStartSectionRotationMarker()
                } else if (Object.keys(tubeInfoRef.current.middleSection).length > 0) {
                    setMiddleSectionRotationMarker(0)
                }
            } else if (partConnectionsValue.length === 1) {
                const markerName = partConnectionsValue[0].partMarkerName
                const markerRef = getMarkerRef(scene, tube.id, markerName)
                if (markerRef && markerRef.userData.middleSection) {
                    rotationAuxInfo.current.checkGuidelines = true
                }
                if (partConnectionsValue[0].slidePosition && markerRef) {
                    markerRef.userData.slidePosition = partConnectionsValue[0].slidePosition
                }
                rotationMarkerRef.current = markerRef
                rotationAuxInfo.current.lastRotationValue = 0
            } else if (partConnectionsValue.length === 2
                && areMarkersOpposite(
                    partConnectionsValue[0].partMarkerName,
                    partConnectionsValue[1].partMarkerName
                )
            ) {
                const markerName = partConnectionsValue[0].partMarkerName
                const markerRef = getMarkerRef(scene, tube.id, markerName, true)
                if (markerRef && markerRef.userData.middleSection) {
                    rotationAuxInfo.current.checkGuidelines = true
                }
                if (partConnectionsValue[0].slidePosition && markerRef) {
                    markerRef.userData.slidePosition = partConnectionsValue[0].slidePosition
                }
                rotationMarkerRef.current = markerRef

                rotationAuxInfo.current.lastRotationValue = 0
                //console.log(markerRef, "markerRef collineal case", tube.id)
            } else {
                const markerName = partConnectionsValue[0].partMarkerName
                const markerRef = getMarkerRef(scene, tube.id, markerName)

                if (!markerRef) {
                    return
                }

                if (markerRef.userData.middleSection) {
                    rotationAuxInfo.current.checkGuidelines = true
                }
                if (partConnectionsValue[0].slidePosition) {
                    markerRef.userData.slidePosition = partConnectionsValue[0].slidePosition
                }
                rotationMarkerRef.current = markerRef
                rotationAuxInfo.current.lastRotationValue = 0
                //console.log(markerRef, "markerRef else case", tube.id)
            }
        } else {
            rotationMarkerRef.current = undefined
        }
    }, [partConnectionsValue, tubeInfoRef.current.attachmentPoint, initialMarkerName,])

    //debugger
    // useEffect(() => {

    //     if (rotationMarkerRef.current && rotationMarkerRef.current.isMesh) {
    //         const currentColor = rotationMarkerRef.current.material.color.getHexString()
    //         if (currentColor === "ff0000") {
    //             rotationMarkerRef.current.material.color.set("purple")
    //         } else {
    //             rotationMarkerRef.current.material.color.set("yellow")
    //         }
    //         rotationMarkerRef.current.material.depthTest = false
    //     }
    // }, [rotationMarkerRef.current,])
    //debugger-end



    const createRotationMarkerAux = (rotationMarker: Object3D) => {
        const position
            = rotationMarker.userData.slidePosition || MeshUtils.copyWorldPosition(rotationMarker)

        const rotationAttachmentMarkerAux = new Mesh()
        const auxMarkerPosition = position
        const auxMarkerQuaternion = MeshUtils.copyWorldQuaternion(rotationMarker)
        rotationAttachmentMarkerAux.position.copy(auxMarkerPosition)
        rotationAttachmentMarkerAux.quaternion.copy(auxMarkerQuaternion)
        rotationAttachmentMarkerAux.attach(tubeInfoRef.current.attachmentPoint!)
        scene.add(rotationAttachmentMarkerAux)
        return rotationAttachmentMarkerAux
    }

    const repositionAttachmentPoint = () => {
        const attachmentPointPosition = MeshUtils.copyWorldPosition(
            tubeInfoRef.current.attachmentPoint!)
        const attachmentPointQuaternion = MeshUtils.copyWorldQuaternion(
            tubeInfoRef.current.attachmentPoint!)
        tubeInfoRef.current.attachmentPoint!.removeFromParent()
        tubeInfoRef.current.attachmentPoint!.position.copy(attachmentPointPosition)
        tubeInfoRef.current.attachmentPoint!.quaternion.copy(attachmentPointQuaternion)
    }

    const onRotationChange = (value: number) => {
        if (rotationMarkerRef.current) {
            //create aux marker based on the rotation marker and add
            //it to the scene and add the add the attachment point as its child
            const rotationAttachmentMarkerAux = createRotationMarkerAux(rotationMarkerRef.current)
            //rotate the aux marker
            rotationAttachmentMarkerAux.rotation.z
                = rotationAttachmentMarkerAux.rotation.z
                + MathUtils.degToRad(value - rotationAuxInfo.current.lastRotationValue)
            //store the aux marker postion -> remove aux as attachment point parent ->
            //put the aux marker position
            repositionAttachmentPoint()
            //remove aux marker
            rotationAttachmentMarkerAux.remove()
            scene.add(tubeInfoRef.current.attachmentPoint!)
            if (rotationAuxInfo.current.checkGuidelines) {
                SegmentedTubeUtils.checkAlignments(
                    scene,
                    tube,
                    tubeInfoRef,
                    partConnectionsValue,
                    [SegmentedTubeSectionType.START, SegmentedTubeSectionType.END,],
                    scene.children
                )
            }
            updateTransforms()
            rotationAuxInfo.current.lastRotationValue = value
        }
    }

    const onRotationSave = (value: number, skipConnectionSaving: boolean = false) => {
        setActualRotation(value)

        //console.log("onRotationSave", value, skipConnectionSaving)

        if(!skipConnectionSaving) {
            messageUtils.custom("Updating connections...", {
                duration: 1, forceShow: true, showSpinner: true,
            },)
            const { newConnections, existingConnections, }
                = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                    scene,
                    tube,
                    tubeInfoRef,
                    partConnectionsValue,
                    connectionTypes,
                    connections,
                    movableSection,
                    [],
                    [],
                    true,
                    undefined,
                    true,
                    boundingBox,
                )
            //console.log(partConnectionsValue, "partConnectionsValue", tubeId)
            //console.log(newConnections, "newConnections", tubeId)
            //console.log(existingConnections, "existingConnections", tubeId)

            //function to get the destination marker name
            const getMatchingDestinationMarkerName
                = (partConnectionsValue: any[], connectedPartId: string,) => {
                    for (const connection of partConnectionsValue) {
                        if (connection.destinationPartId === connectedPartId) {
                            return connection.destinationMarkerName
                        }
                    }
                    return null
                }


            const normalizeMarkerName = (markerName: string) => {
                return markerName.replace(/^(inner|outer)/, "")
            }
            let connectionsToUnsnap

            if (existingConnections.length === 0) {
                // If existingConnections is empty, unsnap all partConnectionsValue
                connectionsToUnsnap = partConnectionsValue
            } else {
                const isConnectionInExistingConnections = (partConnection: any) => {
                    return existingConnections.some(existingConnection =>
                        existingConnection.partB.partId === partConnection.destinationPartId
                        && normalizeMarkerName(existingConnection.partA.markerName) === normalizeMarkerName(partConnection.partMarkerName)
                        && normalizeMarkerName(existingConnection.partB.markerName) === normalizeMarkerName(partConnection.destinationMarkerName)
                    )
                }

                connectionsToUnsnap = partConnectionsValue.filter(connection => !isConnectionInExistingConnections(connection))
            }

            //console.log(connectionsToUnsnap, "connectionsToUnsnap", tubeId)
            //these are the connections that were connected before but they are no longer connected

            if (unsnap && connectionsToUnsnap.length > 0) {
                connectionsToUnsnap.forEach(connection => {
                    unsnap(tube.id, [connection.partMarkerName,])
                    //console.log("Unsnap", "connectedPartId", tube.id, "partMarkerName", connection.partMarkerName, "tubeId", tubeId)
                })
                SoundHelper.playUnsnap()
            }

            //get all the partsIds of parts in newConnections
            const connectedPartIds = newConnections.reduce((acc, connection) => {
                const { partA, partB, } = connection
                if (partA.partId === tube.id) {
                    acc.push(partB.partId)
                } else if (partB.partId === tube.id) {
                    acc.push(partA.partId)
                }
                return acc
            }, [] as string[])



            //give me the destinations markers of the parts that may already be connected
            //remove any cases where the new connection part doesn't have a destination marker name
            // posssibly bc it's not already connected
            const connectedPartIdsWithMarkers = connectedPartIds
                .map(connectedPartId => {
                    const destinationMarkerName
                        = getMatchingDestinationMarkerName(partConnectionsValue, connectedPartId)
                    return destinationMarkerName ? { connectedPartId, destinationMarkerName, } : null
                })
                .filter(item => item !== null)

            if (unsnap && newConnections.length > 0) {
                connectedPartIdsWithMarkers
                    .filter((item): item is {
                        connectedPartId: string,
                        destinationMarkerName: any,
                    } => item !== null)
                    .forEach(({ connectedPartId, destinationMarkerName, }) => {
                        unsnap(connectedPartId, [destinationMarkerName,],)
                        //console.log("Unsnap", "connectedPartId", connectedPartId, "partMarkerName",
                        //destinationMarkerName, "tubeId", tubeId)
                    })
            }

            //multipleUpdate([], newConnections,)

            if (newConnections.length > 0) {
                SoundHelper.playSnap()
                //console.log("newConnections XX", newConnections)
            }
            rotationAuxInfo.current.tempConnections = newConnections
    }
        const newPosition = MeshUtils.copyWorldPosition(tubeInfoRef.current.attachmentPoint!)
        tubeInfoRef.current.attachmentPoint!.rotateY(MathUtils.degToRad(-180))
        const newQuaternion = MeshUtils.copyWorldQuaternion(tubeInfoRef.current.attachmentPoint!)
        tubeInfoRef.current.attachmentPoint!.rotateY(MathUtils.degToRad(180))
        updateTubeValues(tubeId, (tube) => {
            tube.position = { x: newPosition.x, y: newPosition.y, z: newPosition.z, }
            tube.rotation = {
                x: newQuaternion.x,
                y: newQuaternion.y,
                z: newQuaternion.z,
                w: newQuaternion.w,
            }
        })
    }
    const onRotationUndoRedo = () => {
        const { newConnections, }
            = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                scene,
                tube,
                tubeInfoRef,
                partConnectionsValue,
                connectionTypes,
                connections,
                movableSection,
                [],
                [],
                true,
                undefined,
                true,
                boundingBox,
            )

        //get all the partsIds of parts in newConnections
        const connectedPartIds = newConnections.reduce((acc, connection) => {
            const { partA, partB, } = connection
            if (partA.partId === tube.id) {
                acc.push(partB.partId)
            } else if (partB.partId === tube.id) {
                acc.push(partA.partId)
            }
            return acc
        }, [] as string[])


        //function to get the destination marker name
        const getMatchingDestinationMarkerName
            = (partConnectionsValue: any[], connectedPartId: string,) => {
                for (const connection of partConnectionsValue) {
                    if (connection.destinationPartId === connectedPartId) {
                        return connection.destinationMarkerName
                    }
                }
                return null
            }

        //give me the destinations markers of the parts that may already be connected
        //remove any cases where the new connection part doesn't have a destination marker name
        // posssibly bc it's not already connected
        const connectedPartIdsWithMarkers = connectedPartIds
            .map(connectedPartId => {
                const destinationMarkerName
                    = getMatchingDestinationMarkerName(partConnectionsValue, connectedPartId)
                return destinationMarkerName ? { connectedPartId, destinationMarkerName, } : null
            })
            .filter(item => item !== null)

        if (unsnap && newConnections.length > 0) {
            connectedPartIdsWithMarkers
                .filter((item): item is {
                    connectedPartId: string,
                    destinationMarkerName: any,
                } => item !== null)
                .forEach(({ connectedPartId, destinationMarkerName, }) => {
                    unsnap(connectedPartId, [destinationMarkerName,],)
                })
        }

        if (newConnections.length > 0) {
            SoundHelper.playUnsnap()
        }
        rotationAuxInfo.current.tempConnections = newConnections
    }

    const handleMouseDown = () => {
        setHideAddPartButtons(true)
    }

    const handleMouseUp = () => {
        setHideAddPartButtons(false)
    }

    const onFinishEditing = () => {

        setTubeUI(SEGMENTED_TUBE_UI.NONE)
    }

    const getRotation = () => {
        return actualRotation
    }

    const canRotate = () => {
        return !tube.rotationDisabled && !!rotationMarkerRef.current
    }

    const getRotationMarker = () => {
        return rotationMarkerRef.current
    }

    const setStartSectionRotationMarker = () => {
        const markerKey = Object.keys(tubeInfoRef.current.startSection).find(key => {
            const section = tubeInfoRef.current.startSection[key]
            return section && section.outer && !section.outer.userData.lateralFacing
        })

        if (markerKey) {
            const markerRef = tubeInfoRef.current.startSection[markerKey].outer
            if (markerRef) {
                rotationAuxInfo.current.checkGuidelines = false
                rotationAuxInfo.current.lastRotationValue = 0
                rotationMarkerRef.current = markerRef
                setActualRotation(0)
            } else {
                console.warn("Marker reference is undefined for key:", markerKey)
            }
        } else {
            console.warn("No suitable marker found in startSection")
        }
    }

    const setMiddleSectionRotationMarker = (side: number) => {
        const markerSide = Object.keys(tubeInfoRef.current.middleSection)[side]
        const markerPosition = Math.ceil(Object.keys(
            tubeInfoRef.current.middleSection[markerSide]
        ).length / 2)
        const markerRef = tubeInfoRef.current.middleSection[markerSide][markerPosition].outer!
        rotationAuxInfo.current.checkGuidelines = true
        rotationAuxInfo.current.lastRotationValue = 0
        rotationMarkerRef.current = markerRef
        setActualRotation(0)
    }

    const onRotationAxisChange = (axis: ROTATION_AXES_ENUM) => {
        switch (axis) {
            case ROTATION_AXES_ENUM.X:
                setStartSectionRotationMarker()
                break
            case ROTATION_AXES_ENUM.Y:
                setMiddleSectionRotationMarker(0)
                break
            case ROTATION_AXES_ENUM.Z:
                setMiddleSectionRotationMarker(1)
                break
            default:
                throw new Error("Rotation axis mus't be X, Y or Z")
        }
    }

    const getRotationAxesButtons = () => {
        if (partConnectionsValue.length === 0) {
            return <RotationAxesButtons
                onRotationAxisChange={onRotationAxisChange}
            />
        }
        return undefined
    }

    const steps = tube.rotationSteps || 15

    useEffect(() => {
        if (rotationAuxInfo.current.tempConnections.length > 0) {
            multipleUpdate(
                [],
                rotationAuxInfo.current.tempConnections
            )
            rotationAuxInfo.current.tempConnections = []
        }
    }, [tubeUI, rotationAuxInfo.current.tempConnections.length,])

    useEffect(() => {
        setActualRotation(0)
    }, [tube.length,])

    useEffect(() => {
        if (userRotation && tubeInfoRef.current.attachmentPoint) {
            setInitialRotation(userRotation)
        }
    }, [tubeInfoRef.current.attachmentPoint, initialMarkerName,])

    const setInitialRotation = (userRotation: number) => {
        onRotationChange(userRotation)
        onRotationSave(userRotation, true)
        setUserRotationApplied()
    }

    const rotationSliderConfig = {
        onRotationChange,
        onRotationSave,
        onRotationUndoRedo,
        handleMouseDown,
        handleMouseUp,
        onFinishEditing,
        getRotation,
        steps,
        canRotate,
        getRotationMarker,
        getRotationAxesButtons,
    }

    return {
        rotationSliderConfig,
    }
}