/* eslint-disable max-statements */
import React, { MouseEvent, useEffect, useState } from "react"
import { DesignTypeAPI } from "../../../../../common/api/Types"
import MenuIcon from "../../../../assets/Options.svg"
import ActionButton from "../../../../../common/components/ActionButton"
import NameModal from "../../../../../common/components/NameModal"
import { useUnmountDesign } from "../../../../../builder/exportables"
import styled from "styled-components"
import {
    TextContent, Text
} from "../../../../../common/components/Card"
import { useNavigate, useParams } from "react-router-dom"
import { message } from "antd"
import { useRecoilState, useResetRecoilState } from "recoil"
import ConfirmModal from "../../ConfirmModal"
import { layoutState } from "../../../../../builder/exportables"
import { getUser } from "../../../../../common/api/firebase/firebaseUtils"
import { DesignsApi } from "../../../../../common/api/firebase/designs"
import { pendingChangesAtom } from "../../../../../builder/exportables"
import { UnsavedChangesModal } from "../../unsavedChangesModal"
import { getUrlWithQueriesToPreserve } from "../../../../../common/utils/utils"
import { RoutesHelper } from "../../../../../common/utils/routesHelper"

type Props = {
    design: DesignTypeAPI,
    onDuplicateDesign: (positionId: string, copy: DesignTypeAPI) => void,
    removeDesign: (id: string) => void,
}


interface PropsContainer {
    disabled?: boolean;
}
const DesignItem = (props: Props) => {
    const resetLayoutState = useResetRecoilState(layoutState)
    const [pendingChanges, setPendingChanges,] = useRecoilState(pendingChangesAtom)
    const [showOptions, setShowOptions,] = useState(false)
    const [showRenameModal, setShowRenameModal,] = useState(false)
    const [showDeleteModal, setShowDeleteModal,] = useState(false)
    const [showUnsavedChanges, setShowUnsavedChanges,] = useState(false)
    const [duplicating, setDuplicating,] = useState(false)
    const [deleting, setDeleting,] = useState(false)
    const unmount = useUnmountDesign()
    const navigate = useNavigate()
    const { designId, } = useParams()

    const handleCancelRename = () => {
        setShowRenameModal(false)
    }

    const handleRename = async (newName: string) => {
        const name = await DesignsApi.renameDesign(props.design.id, newName)
        props.design.name = name
        setShowRenameModal(false)
    }

    const handleDelete = async () => {
        try {
            setDeleting(true)
            await DesignsApi.deleteDesign(props.design.id)
            props.removeDesign(props.design.id)
        } catch (e: any) {
            message.error("Couldn't delete design")
        } finally {
            setDeleting(false)
        }
    }

    const goToDesign = () => {
        const user = getUser()!
        unmount()
        setPendingChanges(false)
        const newUrl = getUrlWithQueriesToPreserve(RoutesHelper.generateIdRoute(
            {
                userId: user.uid, designId: props.design.id,
            }))
        // we switched to full window reload for performance reasons
        // navigate(newUrl)
        window.location.href = newUrl
    }

    const openDesign = () => {
        if (props.design.id === designId) {
            resetLayoutState()
        } else if (pendingChanges) {
            setShowUnsavedChanges(true)
        } else {
            goToDesign()
        }
    }

    useEffect(() => {
        document.addEventListener("click", (event) => {
            setShowOptions(false)
        })
    }, [])

    const handleMenuClick = (e: MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setShowOptions(!showOptions)
    }

    const handleOnRename = (e: MouseEvent) => {
        handleMenuClick(e)
        setShowRenameModal(true)
    }

    const handleOnDelete = (e: MouseEvent) => {
        handleMenuClick(e)
        setShowDeleteModal(true)
    }

    const handleDuplicate = async () => {
        try {
            setDuplicating(true)
            const copy = await DesignsApi.duplicateDesign(props.design.id)
            props.onDuplicateDesign(props.design.id, copy)
        } catch (e: any) {
            message.error("Couldn't duplicate")
        } finally {
            setDuplicating(false)
        }
    }

    const OptionsModal = () => {
        return (
            <ModalActions onMouseLeave={handleMenuClick}>
                <ContentZone>
                    <ActionButton onClick={handleOnRename}>Rename</ActionButton>
                    <ActionButton
                        onClick={handleDuplicate}
                        disabled={duplicating}
                    >{duplicating ? "Duplicating..." : "Duplicate"}</ActionButton>
                    <ActionButton
                        labelColor="#ed3c3c"
                        onClick={handleOnDelete}>Delete</ActionButton>
                </ContentZone>
            </ModalActions>
        )
    }

    const handleNotContinueOnDesignChange = () => {
        setShowUnsavedChanges(false)
    }

    return (
        <Container onClick={openDesign}
            disabled={props.design.id === designId}>

            <Menu onClick={(e) => { e.stopPropagation() }}>
                <Button onClick={handleMenuClick} >
                    <img src={MenuIcon} alt="Menu icon" />
                </Button>
                {showOptions
                    && <OptionsModal />}
                {showRenameModal
                    && <NameModal
                        inputInitialValue={props.design.name}
                        onRename={handleRename}
                        onCancel={handleCancelRename}
                        loading={!showRenameModal}
                    />}
                {showDeleteModal
                    && <ConfirmModal
                        title="Delete Design"
                        message={`Are you sure you want to delete ${props.design.name}`}
                        confirmButtonText="Delete"
                        confirmButtonColor="#FF0000"
                        onConfirm={handleDelete}
                        onCancel={() => setShowDeleteModal(false)}
                        loading={deleting}
                    />
                }
                {showUnsavedChanges
                    && <UnsavedChangesModal
                        onConfirm={goToDesign} onCancel={handleNotContinueOnDesignChange} />
                }
            </Menu>
            <CardContainer
                disabled={props.design.id === designId}>
                <ImageContainer>
                    <Image src={props.design.imageBase64} />
                </ImageContainer>
                <TextContent>
                    <Text>
                        {props.design.name}
                    </Text>
                </TextContent>
            </CardContainer>
        </Container>
    )
}


const Container = styled.div<PropsContainer>`
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    border-radius: 6px;
    background: white;
    transition: all 0.16s ease-in-out;
    padding: 6px;
    transform: scale(1);
    z-index: 0;

    &:hover {
        background: #f3f3f3;
        transform: scale(1.05);
        z-index: 3;
    }
`

const CardContainer = styled.div<PropsContainer>`
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    
`

const ImageContainer = styled.div`
    position: relative;
`

const Image = styled.img`
    object-fit: scale-down;
    width: 100%;
    height: 100%;
    border: solid 1px #0000001f;
    box-shadow: 0 10px 15px -19px black;
    border-radius: 10px;
`

const Menu = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 6px;
    position: absolute;
    right: 9px;
    top: 9px;
    z-index: 2;
    transform: translate3d(0, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
`

const Button = styled.button`
    width: 80%;
    height: 80%;
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #00000015;
    }
    
    img {
        height: 17px;
    }
`

const ModalActions = styled.div`
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 1;
    width: 100px;
    height: 150px;
    width: 129px;
    animation: open 0.16s ease-in-out 0s;

    @keyframes open {
        0%{
            opacity: 0;
            transform: translateY(10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
`

const ContentZone = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 12px 28px -10px #00000054;

    button{
        flex-grow: 1;
        border-radius: 0;
    }
    button:first-child {
        border-radius: 6px 6px 0px 0px;
    }
    button:last-child {
        border-radius: 0px 0px 6px 6px;
    }
    button:only-child {
        border-radius: 6px;
    }
`

export default DesignItem