import {
    AxesHelper,
    Box3,
    Group,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Quaternion,
    Vector3
} from "three"
import { convertInToCm } from "../../components/main/DesignScreen/utils/UnitUtils"
import { DragMesh, FreePositions } from "./types"
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry"
import { getFirstAndLastSides } from "./meshHelpers"

function getMeshCorners(sides: any, threshold = 0) {
    const points: any = []
    const firstAndLastSides = getFirstAndLastSides(sides)

    firstAndLastSides.forEach(key => {
        const outerMesh = sides[key].outer
        let realBoundary = convertInToCm(outerMesh.userData.boundary) / 100
        if (!realBoundary) {
            realBoundary = 0.1
        }

        // Add threshold to the boundary
        realBoundary += threshold

        const localPositivePosition = new Vector3(0, realBoundary, 0)
        const globalPositivePosition = outerMesh.localToWorld(localPositivePosition.clone())
        points.push(globalPositivePosition)

        // Negative side of the boundary
        const localNegativePosition = new Vector3(0, -realBoundary, 0)
        const globalNegativePosition = outerMesh.localToWorld(localNegativePosition.clone())
        points.push(globalNegativePosition)
    })

    return points
}

function getScaledPointsArray(points: Vector3[]) {
    const scale = 50
    const center = new Vector3()
    points.forEach((p: Vector3) => center.add(p))
    center.divideScalar(points.length)

    const scaledPoints = points.map((p: Vector3) => {
        const scaledPoint = p.clone().sub(center)
        scaledPoint.multiplyScalar(scale)
        return scaledPoint.add(center)
    })
    return scaledPoints
}

function getDragMeshForMiddle(sides: any, viewSurfaceMesh = false, viewDragMesh = false) {
    const points = getMeshCorners(sides)
    const scaledPoints = getScaledPointsArray(points)
    const scaledGeometry = new ConvexGeometry(scaledPoints)
    const geometry = new ConvexGeometry(points)

    const meshMaterial = new MeshBasicMaterial({
        color: 0x0000ff,
        opacity: viewSurfaceMesh ? 0.4 : 0,
        transparent: true,
        wireframe: false,
    })

    // const scaledMeshMaterial = meshMaterial.clone()
    // scaledMeshMaterial.opacity = viewDragMesh ? 0.4 : 0

    const scaledMeshMaterial = new MeshBasicMaterial({
        color: 0xff0000,
        opacity: viewDragMesh ? 0.4 : 0,
        transparent: true,
        wireframe: false,
    })


    const scaledMesh = new Mesh(scaledGeometry, scaledMeshMaterial)
    const mesh = new Mesh(geometry, meshMaterial)
    scaledMesh.geometry.computeBoundingBox()
    const boundingBox = new Box3().setFromObject(mesh)
    const center = new Vector3()
    boundingBox.getCenter(center)

    return {
        dragMesh: scaledMesh,
        surfaceMesh: mesh,
        boundingBox,
        center,
    } as DragMesh
}

function getPointsArrayForMiddle(
    markerArray: Mesh[],
    segmentLength = 0.025,
    extendSurface = false,
    stepDensity = 1,
    pieceLength?: number
) {
    const points: FreePositions[] = []

    const step = convertInToCm(segmentLength) / 100 / stepDensity
    const markers = getExtraMarkers(markerArray, step, stepDensity - 1)
    let extendedPoints: FreePositions[] = []
    const tolerance = 1.5
    const pieceLengthInCm = convertInToCm(pieceLength || 0) / 100

    markers.forEach((marker: Mesh) => {
        const modelBoundary = marker.userData.boundary
        let realBoundary = convertInToCm(modelBoundary) / 100

        if (!modelBoundary) {
            realBoundary = step - 0.0001 // single line mesh
        }

        const pointsForMarker = getPointsForMarker(realBoundary, step, marker)
        // console.log("pointsForMarker result", pointsForMarker)
        const markerWorldPosition = new Vector3()
        marker.getWorldPosition(markerWorldPosition)
        // window.drawVector3Point(markerWorldPosition, "red", 0.0005, 10000)
        points.push(...pointsForMarker)
        if (extendSurface && modelBoundary) {
            realBoundary += (pieceLengthInCm * tolerance)
        }

        const extendedPointsForMarker = getPointsForMarker(realBoundary, step, marker)
        extendedPoints.push(...extendedPointsForMarker)
    })

    const firstMesh = markers[0]
    const lastMesh = markers[markers.length - 1]
    const modelBoundary = firstMesh.userData.boundary

    if (extendSurface && modelBoundary) {
        extendedPoints = extendPoints(extendedPoints, firstMesh, lastMesh, step, pieceLength)
    } else {
        extendedPoints = points
    }

    return { freePositions: extendedPoints, boundingBoxPoints: points, }
}

function getExtraMarkers(markers: Mesh[], step: number, stepDensity: number) {
    const extraMarkers: Mesh[] = []

    const group = new Group()
    const helper = new Object3D()
    group.add(helper)


    markers.forEach((marker) => {
        extraMarkers.push(marker)

        const markerWorldPosition = new Vector3()
        marker.getWorldPosition(markerWorldPosition)

        helper.position.x = 0
        if (!marker.userData.boundary) {
            return
        }

        for (let i = 0; i < stepDensity; i++) {
            const markerWorldPosition = new Vector3()
            marker.getWorldPosition(markerWorldPosition)
            const markerWorldQuaternion = new Quaternion()
            marker.getWorldQuaternion(markerWorldQuaternion)

            group.position.copy(markerWorldPosition)
            group.quaternion.copy(markerWorldQuaternion)

            // helper.position.x += step
            helper.position.add(new Vector3(step, 0, 0))

            const helperWorldPosition = new Vector3()
            helper.getWorldPosition(helperWorldPosition)

            // window.drawVector3Point(markerWorldPosition, "green", 0.0005, 10000)
            // window.drawVector3Point(helperWorldPosition, "red", 0.0006, 10000)

            const extraMarker = marker.clone()
            extraMarker.position.copy(helperWorldPosition)
            extraMarker.quaternion.copy(markerWorldQuaternion)
            extraMarkers.push(extraMarker)
        }
    })
    return extraMarkers
}

function getPointsForMarker(
    boundary: number,
    step: number,
    marker: Mesh
) {
    const scaleFactor = 100000
    const meshName = marker.name
    const scaledStep = step * scaleFactor
    const scaledLimit = boundary * scaleFactor
    const points = []

    for (let i = 0; i <= scaledLimit; i += scaledStep) {
        const unscaledStep = i / scaleFactor
        const localPositivePosition = new Vector3(0, unscaledStep, 0)
        const globalPositivePosition = marker.localToWorld(localPositivePosition.clone())
        const point = {
            meshName: meshName,
            position: globalPositivePosition,
            withinMesh: true,
            marker: marker,
        }
        points.push(point)
    }

    for (let i = 0; i >= -scaledLimit; i -= scaledStep) {
        const unscaledStep = i / scaleFactor
        const localNegativePosition = new Vector3(0, unscaledStep, 0)
        const globalNegativePosition = marker.localToWorld(localNegativePosition.clone())
        const point = {
            meshName: meshName,
            position: globalNegativePosition,
            withinMesh: true,
            marker: marker,
        }
        points.push(point)
    }

    return points
}

function extendPoints(
    points: FreePositions[],
    firstMesh: Mesh,
    lastMesh: Mesh,
    step: number,
    pieceLength: number | undefined
) {
    const newPoints = [...points,]

    const group = new Group()
    const helper = new Object3D()
    group.add(helper)

    const tolerance = 1.5
    const pieceLengthInCm = convertInToCm(pieceLength || 0) / 100
    const pieceLengthInSteps = pieceLengthInCm / step
    const repetitionsWithTolerance = Math.ceil(pieceLengthInSteps * tolerance)

    const realBoundary = convertInToCm(lastMesh.userData.boundary) / 100
    const extendedBoundary = realBoundary + (pieceLengthInCm * tolerance)
    const scaleFactor = 100000
    const scaledStep = step * scaleFactor
    const scaledLimit = extendedBoundary * scaleFactor

    function addPoints(
        mesh: Mesh,
        direction: number,
    ) {
        mesh.getWorldPosition(group.position)
        mesh.getWorldQuaternion(group.quaternion)
        helper.position.y = 0

        for (let x = 1; x < repetitionsWithTolerance; x++) {
            helper.position.x = direction * step * x
            const worldPosition = new Vector3()
            helper.getWorldPosition(worldPosition)
            newPoints.push({
                meshName: mesh.name,
                position: worldPosition,
                withinMesh: false,
                marker: mesh,
            })

            const addVerticalPoints = (i: number) => {
                const unscaledStep = i / scaleFactor
                helper.position.y = unscaledStep
                const globalPosition = new Vector3()
                helper.getWorldPosition(globalPosition)
                newPoints.push({
                    meshName: mesh.name,
                    position: globalPosition,
                    withinMesh: false,
                    marker: mesh,
                })
            }

            for (let i = 0; i <= scaledLimit; i += scaledStep) {
                addVerticalPoints(i)
            }

            for (let i = 0; i >= -scaledLimit; i -= scaledStep) {
                addVerticalPoints(i)
            }
        }
    }

    addPoints(
        lastMesh, -1,
    )
    addPoints(
        firstMesh, 1,
    )

    return newPoints
}

export { getDragMeshForMiddle, getPointsArrayForMiddle, }
