/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { useGLTF } from "@react-three/drei"
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react"
import {
    Snapshot, useRecoilCallback, useRecoilState,
    useRecoilValue, useSetRecoilState
} from "recoil"
import {
    Box3,
    BoxHelper,
    Color,
    DoubleSide,
    Euler,
    Group,
    InstancedMesh,
    MathUtils,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PlaneGeometry,
    Quaternion,
    SphereGeometry,
    Vector3
} from "three"
import { assertPartType, Marker, MarkerType, PartTypeEnum } from "../../../../../../../utils/Types"
import { ConnectorInternalsType, ConnectorMarkerType, CONNECTOR_UI } from "./types/types"
import ConnectorUI from "./ui/ConnectorUI"
import useInstancedMesh from "../../../../../../../providers/instancedMesh/useInstancedMesh"
import useCollision from "../../../../../../../providers/collisionProvider/useCollision"
import useSelector from "../../../../../../../providers/mouseProvider/useSelector"
import { addPartModal, isItemSelected, } from "../../../../../../../state/atoms"
import { allConnections, partConnections, } from "../../../../../../../state/scene/selectors"
import {
    useMultipleUpdates, useNewPart,
    useUpdateConnector,
} from "../../../../../../../state/scene/setters"
import { partsIdsSelector, partsSelector, } from "../../../../../../../state/scene/atoms"
import { initialData } from "../../../../../../../state/atoms"

import {
    attachTo,
    detachFrom,
    getConnectorInternals,
    resetMarkerValues,
    setMarkerDirAndPos,
    setRotation,
    getInitialMarker,
    getRotationMarker,
    saveRotation as saveFinalRotation,
    MAIN_MODEL,
    handleConnectorSwap,
    removeGuidelines,
    canSwapConnector,
    checkExactSnap,
} from "./utils/ConnectorUtils"
import useCamera from "../../../../../../../providers/cameraProvider/useCamera"
import {
    getConnectionMarkerName,
    getOtherPartOfTheConnection,
    isInConnection,
    useGetMarkerData
} from "../../../../../../../state/scene/util"
import {
    connectionTypesSelector,
    sizesSelector
} from "../../../../../../../state/initialDataSelectors"
import { innerToOuter, isInner, MarkerUserData, } from "../../../../../../../utils/MarkerUtil"
import { message } from "antd"
import { getFreeMarkers, handleCollisionOnCreation } from "../../../../../../../utils/PartUtils"
import useModal from "../../../../../../../../common/providers/modalProvider/useModal"
import {
    useRegisterMultipleMovement
} from "../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import NewPartButtonConnector from "./newPartButton"
import { MultipleMovementUtils } from "./utils/MultipleMovementUtils"
import { PartConnectionType } from "../../../../../../../state/scene/types"
import useWoodTexture from "../hooks/useWoodTexture"
import { useThree } from "@react-three/fiber"
import { MeshUtils } from "../../../../../../../utils/MeshUtils"
import { SoundHelper } from "../../../../utils/SoundHelper"
import { breadcrumb } from "../../../../../../../../common/utils/sentrySetup"
import { SELECTED_PART_COLOR } from "../../../../../../../../common/utils/utils"
import useCloseMarkers from "../../../../../../../providers/closeMarkersProvider/useCloseMarkers"
import { useComponentRegistry, useRegisterComponent } from
    "../../../../../../../providers/multiselectProvider/useComponentMethods"
import { useLevaControls } from "../../../../../../../providers/debugProvider/useLevaControls"
import MarkerHelpers from "../utils/markerHelpers/MarkerHelpers"
import { MultiSelectContext } from
    "../../../../../../../providers/multiselectProvider/MultiSelectProvider"
import { CorrectionsType }
    from "../../../../../../../providers/multipleMovementProvider/MultipleMovementProvider"
import { getRotationMarker as getRotationMarkerUtil }
    from "../../../../../../../state/scene/setters"



interface Props {
    id: string;
    handleDeletePart: (id: string) => void;
}

const Connector = (props: Props) => {

    const { scene, } = useThree()
    const { showModal, } = useModal()
    const partIdsList = useRecoilValue(partsIdsSelector)
    const connector = useRecoilValue(partsSelector({ id: props.id, }))!
    assertPartType(connector, PartTypeEnum.connector)
    const [isSelected, setIsSelected,] = useRecoilState(isItemSelected(connector.id))
    const partConnectionsValue = useRecoilValue(partConnections(connector.id))
    const updateConnectorValues = useUpdateConnector()
    const multipleUpdate = useMultipleUpdates()
    const setNewConnectionData = useSetRecoilState(addPartModal)
    const [connectorUI, setConnectorUI,] = useState<CONNECTOR_UI>(CONNECTOR_UI.NONE)
    const connectionTypes = useRecoilValue(connectionTypesSelector)
    const sizes = useRecoilValue(sizesSelector)
    const getMarkerData = useGetMarkerData()
    const [isColliding, setIsColliding,] = useState(false)
    const [savingRotation, setSavingRotation,] = useState(false)
    const connections = useRecoilValue(allConnections)
    const importedGLTF = useGLTF(connector.fileURL, true)
    const { material, woodTexture, } = useWoodTexture(connector.color, 3, 3)
    const rotationMarker = useRef<string | undefined>(undefined)
    const attachedMarker = useRef<Mesh | undefined>(undefined)
    const connectorInternalsRef = useRef<ConnectorInternalsType>(getConnectorInternals())
    const [tempConnectedMarkers, setTempConnectedMarkers,] = useState<string[]>([])
    const closestMarkersHook = useCloseMarkers(connector.id)
    const { viewInnerMarkers, viewOuterMarkers, } = useLevaControls()
    const [boundingBox, setBoundingBox,] = useState<Box3 | null>(null)
    const allParts = useRecoilValue(initialData)
    const multiSelectContext = useContext(MultiSelectContext)
    const { unregister, } = useComponentRegistry()
    const { drawVector3Point, } = useCamera()
    const wasDuplicated = !!connector.duplicatedFrom
    const [updatingPosition, setUpdatingPosition,] = useState(false)
    const [uiVisible, setUiVisible,] = useState(false)

    const getLatestStatusofDuplication = () => {
        return multiSelectContext?.checkDuplicationStatus()
    }

    const { fit, setInAutofocusMode, addMeshToSceneBounds,
        getBoundingBoxForConnector, } = useCamera()

    const requestidleCallbackWithFallback = (callback: () => void, timeout: number) => {
        if (typeof window !== "undefined" && "requestIdleCallback" in window) {
            (window as any).requestIdleCallback(callback, { timeout, })
        } else {
            setTimeout(callback, timeout)
        }
    }



    const handleConnectorClick = (mesh: InstancedMesh, matrix: Matrix4) => {
        setIsSelected(true)
        multiSelectContext?.setTransformMode("off")
        updateColor(SELECTED_PART_COLOR)
        //console.log(getLatestStatusofDuplication(), "getLatestStatusofDuplication")
        if (getLatestStatusofDuplication() !== "running") {
            setConnectorUI(CONNECTOR_UI.ACTIONS)
            requestidleCallbackWithFallback(() => {
                fit(mesh, matrix)
                setInAutofocusMode(true)
            }, 100)
        }
    }

    const focusAfterCreation = (mesh: InstancedMesh, matrix: Matrix4) => {
        if (!wasDuplicated) {
            setIsSelected(true)
            setConnectorUI(CONNECTOR_UI.ACTIONS)
            fit(mesh, matrix)
            setInAutofocusMode(true)
        }
    }





    const onCollide = (colliding: boolean) => {
        setIsColliding(colliding)
    }

    const setInternals = (newConnectorInternals: ConnectorInternalsType) => {
        connectorInternalsRef.current = newConnectorInternals
    }

    const updateTempConnectedMarkers = (newConnections: PartConnectionType[]) => {
        if (newConnections.length !== tempConnectedMarkers.length
            || tempConnectedMarkers.some(
                m => !newConnections.some(
                    c => getConnectionMarkerName(c, connector.id) === m))
            || newConnections.some(c =>
                tempConnectedMarkers.some(m => getConnectionMarkerName(c, connector.id) === m))
        ) {
            setTempConnectedMarkers(newConnections.map(
                connection => getConnectionMarkerName(connection, connector.id)!))
        }
    }

    const {
        ref,
        updateTransforms,
        deleteMesh,
        instancedMesh,
        updateColor,
        getMatrix, } = useInstancedMesh(
            (importedGLTF as any).nodes[MAIN_MODEL],
            connector.id,
            connector.apiTypeId,
            handleConnectorClick,
            material,
            woodTexture
        )

    const {
        updateCollider,
        buildCollider,
        deleteCollider,
    } = useCollision(
        connector.id,
        ref,
        instancedMesh,
        onCollide,
        connector.type,
        (importedGLTF as any).nodes.colliderModel
    )
    const { registerInstancedMesh, } = useSelector(connector.id, undefined)

    const detachFromMarker = MultipleMovementUtils.useDetachFromMarker({
        connector,
        attachedMarker,
        scene,
        buildCollider,
        updateCollider: (connectedParts: string[]) => updateCollider(undefined, connectedParts),
        connectorInternalsRef,
        setInternals,
        compatibilityList: connectionTypes,
    })
    useRegisterMultipleMovement({
        id: props.id,
        attachToMarker: (marker: Mesh) => {
            marker.attach(attachedMarker.current!)
        },
        detachFromMarker: (
            marker: Mesh,
            connections: PartConnectionType[],
            getExactSnaps?: boolean,
            checkCollisions?: boolean,
            resetGuidelines?: boolean
        ) => {
            const connectorConnections = connections
                .filter(c => isInConnection(c, connector.id))
                .map(c => {
                    return {
                        connectedPartId: getOtherPartOfTheConnection(c, connector.id),
                        connectedMarkerName: getConnectionMarkerName(c, connector.id)!,
                    }
                })
            return detachFromMarker(marker,
                connectorConnections.map(c => c.connectedPartId),
                connectorConnections.map(c => c.connectedMarkerName),
                getExactSnaps,
                checkCollisions,
                resetGuidelines
            )
        },
        updateTransforms,
        checksOnLengthMove: (
            elongationDirection: Vector3,
            connections: PartConnectionType[],
            lengthDirection: number
        ) => {
            return {
                ...MultipleMovementUtils.checkSnap({
                    connector,
                    scene,
                    connectorInternalsRef,
                    connections,
                    setInternals,
                    compatibilityList: connectionTypes,
                    elongationDirection,
                }),
                ...MultipleMovementUtils.checkAlignments({
                    connector,
                    scene,
                    connectorInternalsRef,
                    connections,
                    setInternals,
                    elongationDirection,
                    lengthDirection,
                }),
            } as CorrectionsType
        },
        checksOnSegmentedTubeLengthMove: (
            intersectableMeshes: Object3D[],
            connections: PartConnectionType[]
        ) => {
            MultipleMovementUtils.checkAlignments({
                connector,
                scene,
                connectorInternalsRef,
                connections,
                setInternals,
                intersectableMeshes,
            })
        },
        checksOnRotationMove: (connections: PartConnectionType[]) => {
            MultipleMovementUtils.checkAlignments({
                connector,
                scene,
                connectorInternalsRef,
                connections,
                setInternals,
            })

            const connectorConnections = connections.filter(c => isInConnection(c, connector.id))

            if (
                checkExactSnap(
                    scene,
                    connector,
                    connectorInternalsRef.current,
                    getFreeMarkers(
                        connector,
                        connectorConnections.map(c => getConnectionMarkerName(c, connector.id)!)
                    ),
                    [
                        ...connectorConnections.map(
                            c => getOtherPartOfTheConnection(c, connector.id)),
                        connector.id,
                    ],
                    connectionTypes
                ).length > 0
            ) {
                SoundHelper.playUnsnap()
            }
        },
        getMaxLength: (direction: Vector3, connections?: PartConnectionType[]) => {
            return MultipleMovementUtils.getMaxLength({
                connector,
                scene,
                connectorInternalsRef,
                connections,
                setInternals,
                compatibilityList: connectionTypes,
                direction,
            })
        },
        getPosAndRot: () => {
            const markersRef = connectorInternalsRef.current.markers
            const initialMarker = getInitialMarker(connector, markersRef)

            initialMarker.rotateY(MathUtils.degToRad(-180))
            const pos = MeshUtils.copyWorldPosition(initialMarker)
            const rot = MeshUtils.copyWorldQuaternion(initialMarker)
            initialMarker.rotateY(MathUtils.degToRad(180))
            return { pos, rot, }
        },
    })

    const getColor = () => {
        if (isSelected) {
            return SELECTED_PART_COLOR
        } else {
            return material ? "#fff" : connector.color
        }
    }


    useEffect(() => {
        if (instancedMesh.current && getMatrix()) {
            const bounder = getBoundingBoxForConnector(instancedMesh.current!, getMatrix())
            setBoundingBox(bounder)
        }
        else {
            setBoundingBox(null)
        }
        updateTransforms()

    }, [isColliding, isSelected,])

    const originalColor = () => {
        updateColor(connector.color)
    }


    useEffect(() => {
        if (isSelected) {
            updateColor(SELECTED_PART_COLOR)
        } else {
            updateColor(getColor())
            connectorInternalsRef.current.guidelines.forEach((line) => scene.remove(line))
            setInternals({
                ...connectorInternalsRef.current,
                guidelines: [],
            })
        }
    }, [isSelected,])

    const saveRotation = () => {
        const markersRef = connectorInternalsRef.current.markers
        const marker = getRotationMarker(connector, markersRef) || attachedMarker.current
        breadcrumb({
            message: "After getRotationMarker",
            level: "info",
            data: { marker: marker.userData, },
        })
        if (marker) {
            saveFinalRotation(
                marker,
                scene,
                connectorInternalsRef,
                connector,
                multipleUpdate,
                setSavingRotation,
                partConnectionsValue.map(c => c.partMarkerName),
                connectionTypes,
                buildCollider,
                updateCollider,
                partConnectionsValue.map(connection => connection.destinationPartId),
                connections,
                connector.loaded,
                updateTempConnectedMarkers
            )
            breadcrumb({
                message: "After saveRotation",
                level: "info",
            })
        }
    }

    const detachEverythingFrom = (marker: Mesh) => {
        const markersRef = connectorInternalsRef.current.markers
        const meshesToDetach = Object.values(markersRef)
        detachFrom(marker, meshesToDetach, scene, attachedMarker)
        if (ref.current) {
            marker.remove(ref.current)
            scene.add(ref.current)
        }
    }

    const attachEverythingTo = (marker: Mesh) => {
        const markersRef = connectorInternalsRef.current.markers
        if (ref.current) {
            marker.attach(ref.current)
            const meshesToAttach = Object.keys(markersRef).filter(
                name => name !== marker.name
            )
                .map(m => markersRef[m])
            attachTo(marker, meshesToAttach, attachedMarker)
        }
    }

    const setInitialPositionAndRotation = () => {
        if (attachedMarker.current) {
            resetMarkerValues(attachedMarker.current)
            detachEverythingFrom(attachedMarker.current)
        }
        const markersRef = connectorInternalsRef.current.markers
        const initialMarker = getInitialMarker(connector, markersRef)

        attachEverythingTo(initialMarker)

        setMarkerDirAndPos(
            initialMarker,
            connector.rotation,
            connector.position
        )
        initialMarker.rotateY(MathUtils.degToRad(180))
    }

    const setConnectorPositionBySelectedMarker = () => {
        const markersRef = connectorInternalsRef.current.markers
        const marker = getInitialMarker(connector, markersRef)

        if (marker && ref.current) {
            setInitialPositionAndRotation()
            updateTransforms()
            updateColor(material ? "#fff" : connector.color)
        }
        setRotationMarker()
    }

    useEffect(() => {
        if (!isSelected) {
            setConnectorUI(CONNECTOR_UI.CLOSE)
        }
    }, [connector.id, isSelected,])

    const onUserRotationApplied = () => {
        updateConnectorValues(props.id, (c) => {
            c.userRotation = 0
        }, true)
    }

    useEffect(() => {
        if (connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER && multiSelectContext) {
            multiSelectContext.setSelectionMode(false)
        }
    }, [connectorUI,])

    useEffect(() => {
        rotationMarker.current = connector.rotationMarkerName
            ? connector.rotationMarkerName
            : innerToOuter(connector.markers[0].name)

        updateConnectorValues(props.id, (c) => { c.instanciated = true }, true)
        setConnectorPositionAndRotation(MAIN_MODEL)
        registerInstancedMesh()
        if (connector.loaded || wasDuplicated) {
            setConnectorUI(CONNECTOR_UI.NONE)
        } else {
            setConnectorUI(CONNECTOR_UI.AFTER_CREATION)
        }
        addMeshToSceneBounds()

        return () => {
            updateColor(material ? "#fff" : connector.color)
            deleteMesh()
            deleteCollider()
            if (connectorInternalsRef.current) {
                removeGuidelines(
                    connectorInternalsRef,
                    scene,
                    setInternals
                )
            }
        }
    }, [])



    const setPosAndRotWithNewInitialMarker = (initialMarker: Mesh) => {
        let attachedMarkerAux
        if (attachedMarker.current) {
            attachedMarkerAux = attachedMarker.current
            resetMarkerValues(attachedMarker.current)
            detachEverythingFrom(attachedMarker.current)
        }
        attachEverythingTo(initialMarker)
        initialMarker.rotateY(MathUtils.degToRad(180))
        setMarkerDirAndPos(initialMarker, connector.rotation, connector.position)
        initialMarker.rotateY(MathUtils.degToRad(-180))
        if (attachedMarkerAux) {
            const posAux = MeshUtils.copyWorldPosition(attachedMarkerAux)
            const rotAux = MeshUtils.copyWorldQuaternion(attachedMarkerAux)
            resetMarkerValues(initialMarker)
            detachEverythingFrom(initialMarker)
            attachEverythingTo(attachedMarkerAux)
            setMarkerDirAndPos(attachedMarkerAux, rotAux, posAux)
        }
    }

    useEffect(() => {
        if (connector.instanciated) {
            const markersRef = connectorInternalsRef.current.markers
            const initialMarker = getInitialMarker(connector, markersRef)
            if (savingRotation) {
                setSavingRotation(false)
            }
            if (updatingPosition) {
                setInitialPositionAndRotation()
                setUpdatingPosition(false)
                requestidleCallbackWithFallback(() => {
                    updateCollider()
                }, 100)
            } else if (attachedMarker.current
                && initialMarker.name === attachedMarker.current.name) {
                setMarkerDirAndPos(
                    attachedMarker.current!,
                    connector.rotation,
                    connector.position
                )
                initialMarker.rotateY(MathUtils.degToRad(180))
            } else {
                setPosAndRotWithNewInitialMarker(initialMarker)
            }
        }

        const bounder = getBoundingBoxForConnector(instancedMesh.current!, getMatrix())
        setBoundingBox(bounder)
        updateTransforms()
    }, [connector.position, connector.rotation,])


    const setConnectorPositionAndRotation = (meshName: string) => {
        const mesh = (importedGLTF as any).nodes[meshName] as Mesh
        const pos = mesh.position
        const rot = mesh.rotation
        ref.current?.position.set(pos.x, pos.y, pos.z)
        ref.current?.rotation.set(rot.x, rot.y, rot.z)
        updateTransforms()
    }


    const getExactSnapOnCreation = () => {
        const connectedMarkers = partConnectionsValue.map(c => c.partMarkerName)
        const connectedToPartIds = partConnectionsValue.map(c => c.destinationPartId)
        const newConnections = checkExactSnap(
            scene,
            connector,
            connectorInternalsRef.current,
            getFreeMarkers(connector, connectedMarkers),
            connectedToPartIds,
            connectionTypes
        )
        return newConnections
    }

    const initialSetup = () => {
        setConnectorPositionBySelectedMarker()
        if (!connector.loaded) {
            if (!wasDuplicated) {
                setIsSelected(true)
            }
            fit(instancedMesh.current!, getMatrix()!)
        }
        if (!wasDuplicated) {
            buildCollider()
        }
        setTimeout(() => {

            let connectionsOtherParts: string[] = []

            if (!wasDuplicated) {
                if (!connector.loaded) {
                    const exactSnaps = getExactSnapOnCreation()
                    if (exactSnaps.length > 0) {
                        SoundHelper.playUnsnap()
                    }
                    connectionsOtherParts = exactSnaps.map(
                        c => getOtherPartOfTheConnection(c, connector.id))
                    updateTempConnectedMarkers(exactSnaps)

                    if (connector.rotationDisabled) {
                        setConnectorUI(CONNECTOR_UI.ACTIONS)
                    }
                }


                if (partConnectionsValue.length > 0) {
                    partConnectionsValue.forEach(connection => {
                        connectionsOtherParts.push(connection.destinationPartId)
                    })
                }
                handleCollisionOnCreation({
                    buildCollider,
                    updateCollider: (removePart?: () => void) => updateCollider(
                        removePart, connectionsOtherParts),
                    deleteCollider,
                    partType: PartTypeEnum.connector,
                    onRemove: handleConnectorDelete,
                    showModal,
                })
            }
            closestMarkersHook.updateMarkers(
                Object.values(connectorInternalsRef.current.markers)
            )
        }, 100)

        setTimeout(() => {
            setRotationMarker()
        }, 250)
    }

    useEffect(() => {
        if (connector.instanciated) {
            initialSetup()
        }
    }, [
        connector.id,
        connector.instanciated,
    ])

    const partConnectionsFunc = useRecoilCallback(
        ({ snapshot, }) =>
            () => {
                return snapshot.getLoadable(partConnections(connector.id)).contents
            },
        [],
    )

    const partConnectionsValueFresh = partConnectionsFunc()

    const generateMarker = (
        markersMap: Marker,
        userData: Omit<MarkerUserData, "markerName">,
        inner: boolean
    ) => {
        const models = importedGLTF.nodes.Scene.children as Mesh[]
        const name = inner ? markersMap.name : innerToOuter(markersMap.name)

        userData.innerOuter = isInner(name)
            ? ConnectorMarkerType.inner : ConnectorMarkerType.outer

        const markerMesh = models.filter((m) => m.name === name)[0]
        const markerIsAvailable = !partConnectionsValueFresh.some(
            ({ partMarkerName, }: any) =>
                innerToOuter(partMarkerName) === innerToOuter(markersMap.name)
        )
            && !tempConnectedMarkers.some(
                markerName => innerToOuter(markerName) === innerToOuter(markersMap.name))
        const tooltipSize = Object.values(sizes).find(s => s.id === markersMap.sizeId)?.size!
        if (markerMesh) {
            return (
                <mesh
                    userData={{ ...userData, markerName: name, }}
                    key={name}
                    name={name}
                    ref={(ref) => {
                        connectorInternalsRef.current.markers[name] = ref as Mesh
                    }}
                    scale={markerMesh.scale}
                    position={markerMesh.position}
                    rotation={markerMesh.rotation}
                    geometry={markerMesh.geometry}
                    dispose={null}
                >
                    <meshBasicMaterial
                        alphaTest={0}
                        visible={false}
                        attach="material"
                        color={"#ff0808"}
                        side={DoubleSide}
                    />
                    {(!inner && isSelected && markerIsAvailable)

                        && <NewPartButtonConnector
                            name={name}
                            id={connector.id}
                            markersMapName={markersMap.name}
                            connectionTypes={connectionTypes}
                            connector={connector}
                            connectorInternalsRef={connectorInternalsRef}
                            connectorMeshRef={ref}
                            sizeMarker={tooltipSize}
                            setIsSelected={setIsSelected}
                        />
                    }

                </mesh>
            )
        }
    }

    const getMarkers = useMemo(() => {
        return connector.markers.map((markersMap) => {
            const userData = {
                userDataType: "MarkerUserData",
                id: markersMap.id,
                partId: connector.id,
                partApiId: connector.apiTypeId,
                partType: PartTypeEnum.connector,
                sizeId: markersMap.sizeId,
                innerOuter: isInner(markersMap.name)
                    ? ConnectorMarkerType.inner : ConnectorMarkerType.outer,
                iELength: markersMap.iELenght,
            } as const
            return (
                <Fragment key={markersMap.name}>
                    {generateMarker(markersMap,
                        { ...userData, type: MarkerType.COLLISION, }, true)
                    }
                    {generateMarker(markersMap,
                        { ...userData, type: MarkerType.COLLISION, }, false)
                    }
                </Fragment>
            )
        })
    }, [
        connector.markers,
        isSelected,
        connector.id,
        connector.rotationMarkerName,
        connector.rotation,
        connector.position,
        connector.apiTypeId,
        partConnectionsValue,
    ])



    const handleConnectorDelete = () => {
        props.handleDeletePart(connector.id)
        unregister(connector.id)
    }

    const getBoundingBox = () => {
        const bounder = getBoundingBoxForConnector(instancedMesh.current!, getMatrix())
        return bounder
    }


    const findPartById = (apiTypeId: string) => {
        return allParts?.parts.find(part => part.id === apiTypeId)
    }

    const createPart = useNewPart()

    const duplicatePart = (position: Vector3, initialMarkerName?: string,
        markerOffset?: Vector3, rotation?: Quaternion) => {
        //console.log("duplicatePart", position, tube.id)
        const matchingPart = findPartById(connector.apiTypeId)
        if (!matchingPart) {
            console.warn("part no longer exists and cannot be duplicated", connector.apiTypeId)
            return
        }
        const defaultQuaternion = new Quaternion()
        let newPart: any
        if (matchingPart && position) {
            newPart = {
                part: {
                    ...matchingPart,
                },
                posAndRot: {
                    inner: {
                        pos: position,
                        rot: rotation || defaultQuaternion,
                    },
                    outer: {
                        pos: position,
                        rot: rotation || defaultQuaternion,
                    },
                },
            }
        }
        if (matchingPart && !position) {
            newPart = {
                part: {
                    ...matchingPart,
                },
                posAndRot: {
                    inner: {
                        pos: new Vector3(0, 0, 0),
                        rot: defaultQuaternion,
                    },
                    outer: {
                        pos: new Vector3(0, 0, 0),
                        rot: defaultQuaternion,
                    },
                },
            }
        }
        newPart.duplicatedFrom = connector.id
        newPart.initialMarkerName = initialMarkerName
        const { newPartId, } = createPart(newPart)
        return newPartId
    }

    const getPartInfo = () => {
        return connector
    }

    const updateInitialMarkerName = (initialMarkerName: string) => {
        updateConnectorValues(connector.id, (c) => {
            c.initialMarkerName = initialMarkerName
        }, true)
        setIsSelected(false)
    }

    const setUItoNone = () => {
        setConnectorUI(CONNECTOR_UI.NONE)
        setIsSelected(false)
        setInAutofocusMode(false)
    }

    const getMesh = () => {
        const mesh = instancedMesh.current
        const id = connector.id
        return { mesh, id, }
    }

    const updatePositionAndRotation = (position: Vector3, rotation: Quaternion) => {
        //console.log(position, rotation, "updatePositionAndRotation before update")
        //drawVector3Point(position, scene, 0xffff00, 0.009)

        updateConnectorValues(connector.id, (c) => {
            c.position = position
            c.rotation = rotation
        })
        setUpdatingPosition(true)

        /*setTimeout(() => {
            const bounder = getBoundingBoxForConnector(instancedMesh.current!, getMatrix())
            setBoundingBox(bounder)

            if (!new Vector3(connector.position.x, connector.position.y,
                connector.position.z).equals(position)
            || !new Quaternion(connector.rotation.x, connector.rotation.y,
            connector.rotation.z, connector.rotation.w).equals(rotation)) {

            console.log("Values not updated, retrying...")
            //this was a bug i found and can't find the root cause of so this is a patch for now

            updateConnectorValues(connector.id, (c) => {
                c.position = { x: position.x, y: position.y, z: position.z, }
                c.rotation = { x: rotation.x, y: rotation.y, z: rotation.z, w: rotation.w, }
            })
        }

            console.log(connector.position, connector.rotation, "After timeout check")
        }, 300)*/
    }


    const getAllMarkers = () => {
        return Object.values(connectorInternalsRef.current.markers)
    }

    useRegisterComponent(props.id, {
        updateColor, originalColor,
        deletePart: handleConnectorDelete, getBoundingBox, duplicatePart,
        getPartInfo, updateInitialMarkerName, setUItoNone,
        getMesh, updatePositionAndRotation,
        getAllMarkers,
    })


    const handleSwap = () => {
        handleConnectorSwap({
            connectorValues: connector,
            partConnectionsValue,
            connectionTypes,
            sizes,
            getMarkerData,
            setNewConnectionData,
            partIdsList,
            scene,
            connectorRef: instancedMesh.current!,
        })
    }

    const setRotationMarker = () => {
        const markersRef = connectorInternalsRef.current.markers
        const newRotationMarker = innerToOuter(connector.rotationMarkerName)

        if (connector.rotationMarkerName && connector.instanciated) {
            const markerMesh = markersRef[newRotationMarker]
            if (markerMesh) {
                const pos = new Vector3()
                markerMesh.getWorldPosition(pos)
                const dir = MeshUtils.copyWorldQuaternion(markerMesh)
                let marker = markersRef[rotationMarker.current!]
                resetMarkerValues(attachedMarker.current!)
                detachEverythingFrom(attachedMarker.current!)
                rotationMarker.current = newRotationMarker
                marker = getRotationMarker(connector, markersRef)

                if (marker) {
                    attachEverythingTo(marker)
                    setMarkerDirAndPos(marker, dir, pos)
                }
            } else {
                console.warn(`Rotation marker "${newRotationMarker}" not found in markersRef`)
            }
        }
    }

    useEffect(() => {
        setRotationMarker()
        updateTransforms()
    }, [connector.rotationMarkerName, partConnectionsValue,])



    const handleRotation = (value: number, rotationMarker?: Mesh) => {
        const markersRef = connectorInternalsRef.current.markers
        const marker = rotationMarker ? rotationMarker : getRotationMarker(connector, markersRef)
        if (marker) {
            setRotation(
                value,
                marker,
                scene,
                connectorInternalsRef,
                connector,
                partConnectionsValue,
                setInternals,
                connections
            )
            const connectedMarkers = partConnectionsValue.map(c => c.partMarkerName)
            const connectedToPartIds = partConnectionsValue.map(c => c.destinationPartId)
            const newConnections = checkExactSnap(
                scene,
                connector,
                connectorInternalsRef.current,
                getFreeMarkers(connector, connectedMarkers),
                connectedToPartIds,
                connectionTypes
            )

            updateTempConnectedMarkers(newConnections)

            if (newConnections.length > 0) {
                SoundHelper.playUnsnap()
            }
            updateTransforms()
        } else {
            message.warning("This part can't be rotated")
        }
    }

    const handleRotationStart = () => { }

    const rotationEnd = () => {
        const newConnections = checkExactSnap(
            scene,
            connector,
            connectorInternalsRef.current,
            getFreeMarkers(
                connector,
                partConnectionsValue.map(c => c.partMarkerName)
            ),
            partConnectionsValue.map(c => c.destinationPartId),
            connectionTypes
        )
        updateCollider(
            undefined,
            newConnections.map(c => getOtherPartOfTheConnection(c, connector.id))
        )
    }

    const handleCheckExactSnap = () => {
        return checkExactSnap(
            scene,
            connector,
            connectorInternalsRef.current,
            getFreeMarkers(
                connector,
                partConnectionsValue.map(c => c.partMarkerName)
            ),
            partConnectionsValue.map(c => c.destinationPartId),
            connectionTypes
        )
    }

    const transformMarkersToSides = (markers: { [key: string]: Mesh, }) => {
        const newMarkers: { [key: string]: { inner: Mesh, outer: Mesh, }, } = {}

        Object.keys(markers).forEach(key => {
            const index = key.match(/\d+/)?.[0]
            const type = key.includes("inner") ? "inner" : "outer"

            if (index) {
                if (!newMarkers[index]) {
                    newMarkers[index] = { inner: undefined as any, outer: undefined as any, }
                }
                newMarkers[index][type] = markers[key]
            }
        })

        return newMarkers
    }


    const transformedMarkers = transformMarkersToSides(connectorInternalsRef.current.markers)

    //there are some saves that happens after UI close
    //so we need to wait for these process to finish before hiding the UI
    // since the logic sits there

    //we also need a slight to change UI actions because
    //there are some useEffects that depend on that change to work

    useEffect(() => {
        if (isSelected) {
            setUiVisible(true)
            setConnectorUI(CONNECTOR_UI.NONE)
            const timer = setTimeout(() => {
                setConnectorUI(CONNECTOR_UI.ACTIONS)
            }, 150)
            return () => clearTimeout(timer)
        } else {
            // Delay hiding UI to allow for any final updates
            const timer = setTimeout(() => {
                setUiVisible(false)
            }, 300)
            return () => clearTimeout(timer)
        }
    }, [isSelected,])

    const updateRotationMarkerWhenConnectionsChange = () => {
        const newRotationMarker = getRotationMarkerUtil({
            snapshot: {
                getLoadable: (selector: any) => {
                    if (selector === partConnections(connector.id)) {
                        return {
                            state: "hasValue",
                            contents: partConnectionsValue,
                            getValue: () => partConnectionsValue,
                        }
                    } else {
                        return {
                            state: "hasValue",
                            contents: connector,
                            getValue: () => connector,
                        }
                    }
                },
            } as Snapshot,
            partId: connector.id,
        })

        if (connector.rotationMarkerName !== newRotationMarker && newRotationMarker !== "") {
            updateConnectorValues(connector.id, (c) => {
                c.rotationMarkerName = newRotationMarker
            }, true)
        }
    }

    useEffect(() => {
        updateRotationMarkerWhenConnectionsChange()
    }, [partConnectionsValue,])



    return (
        <Fragment>
            {(viewInnerMarkers || viewOuterMarkers) && <MarkerHelpers
                slideSide={transformedMarkers}
                attachmentPoint={attachedMarker.current}
                onDotClick={() => { }}
                viewInnerMarkers={viewInnerMarkers}
                viewOuterMarkers={viewOuterMarkers}
            />}
            <group ref={(r) => { ref.current = r as Group }}
                userData={{ type: "COLLISION_CONNECTOR", }} />
            {getMarkers}
            {uiVisible && <ConnectorUI
                instancedMesh={instancedMesh.current}
                connector={connector}
                connectorInternalsRef={connectorInternalsRef}
                attachedMarker={attachedMarker}
                attachEverythingTo={attachEverythingTo}
                detachEverythingFrom={detachEverythingFrom}
                handleMouseUp={rotationEnd}
                handleRotationStart={handleRotationStart}
                connectorUI={connectorUI}
                setConnectorUI={setConnectorUI}
                handleConnectorDelete={handleConnectorDelete}
                handleConnectorSwap={handleSwap}
                handleRotation={handleRotation}
                handleConnectorState={saveRotation}
                showSwap={canSwapConnector(connector, partConnectionsValue)}
                buildCollider={buildCollider}
                updateCollider={updateCollider}
                checkExactSnap={handleCheckExactSnap}
                updateTransforms={updateTransforms}
                onUserRotationApplied={onUserRotationApplied}
                boundingBox={boundingBox}
                onDuplicate={multiSelectContext?.duplicateSelectedParts ?? (() => { })}
                setIdsAsHighlightedAndTurnOnControl
                ={multiSelectContext?.setIdsAsHighlightedAndTurnOnControl ?? (() => { })}
                setIsSelected={setIsSelected}
                transformMode={multiSelectContext?.transformMode ?? "off"}
            />}
        </Fragment>
    )
}

export default Connector