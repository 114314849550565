import { getAuth } from "firebase/auth"
import { matchPath } from "react-router"
import * as rudderanalytics from "rudder-sdk-js"
import { EnvHelper } from "./EnvHelper"
import { getSessionTimer, setSessionTimer } from "./LocalPersistence"
import * as Sentry from "@sentry/react"
import { useGetPartsLength } from "../../builder/exportables"

let ip: string

type EventData = {
  totalPartsInDesign: number,
  mainMaterial?: string,
  mainSubmaterial?: string,
  unit?: "cm" | "in",
}

type PartEventData = {
  part: {
    id: string,
    friendlyName: string,
  },
} & EventData

type SwapEventData = {
  part: {
    id: string,
    friendlyName: string,
  },
  partToSwap: {
    id: string,
    friendlyName: string,
  },
} & EventData

type CloneDesignEventData = {
  idClonedDesign: string,
} & EventData

function getSecondsDiff(startDate: number, endDate: number) {
  const msInSecond = 1000

  return Math.round(
    Math.abs(endDate - startDate) / msInSecond
  )
}

export const initRudderAnalytics = () => {
  if (EnvHelper.rudderAnalyticsConfig) {
    const { writeKey, dataPlaneUrl, } = EnvHelper.rudderAnalyticsConfig
    rudderanalytics.ready(() => { })
    rudderanalytics.load(writeKey, dataPlaneUrl)
    startSessionTimer()
  }
}

export const startSessionTimer = () => {
  const timer = getSessionTimer()

  if (!timer) {
    setSessionTimer(
      new Date()
        .getTime()
        .toString()
    )
  }
}

const track = async <T>(eventName: string, data: T) => {
  if (EnvHelper.rudderAnalyticsConfig) {
    const auth = getAuth()
    const email = auth.currentUser?.email ? auth.currentUser.email : undefined
    const authSession = getSessionTime()
    const ip = await getIP()

    const match = matchPath("/design/:userId/:designId", window.location.pathname)
    let designId: string | undefined
    let userId: string | undefined

    if (match?.params?.userId) {
      userId = match.params.userId
    }

    if (match && match.params?.designId) {
      designId = match.params.designId
    }

    const baseData = {
      desingOwnerUserId: userId,
      designId,
      email,
      sessionInSeconds: authSession,
      ip,
    }
    rudderanalytics.track(eventName, { ...baseData, ...data, })
  }
}

const IPIFY_API_KEY = "at_qLV8TdxH9TRohD6mDx6mykBS13VE7" // Replace with your actual API key

const getIP = async () => {
  try {
    if (ip) {
      return ip
    }

    const response = await fetch(`https://geo.ipify.org/api/v2/country?apiKey=${IPIFY_API_KEY}`)
    const data = await response.json()
    ip = data.ip
    return ip
  } catch (err) {
    Sentry.captureException(err, {
      extra: { message: "IP can't be obtained from ipify API", },
    })
    return undefined
  }
}

export const useEventsData = () => {
  const partsLength = useGetPartsLength()

  return <T extends {} = {}>(additionalData: T = {} as T): T & EventData => ({
    totalPartsInDesign: partsLength(),
    ...additionalData,
  })
}

const openPartsList = (data: EventData) => {
  track("OPEN_PARTS_LIST", data)
}

const openBuyPartsTab = (data: EventData) => {
  track("OPEN_BUY_PARTS_TAB", data)
}

const clickBuyParts = (data: EventData) => {
  track("CLICK_BUY_PARTS", data)
}

const addPart = (data: PartEventData) => {
  track("ADD_PART", data)
}

const swapPart = (data: SwapEventData) => {
  track("SWAP_PART", data)
}

const saveDesign = (data: EventData) => {
  track("SAVE_DESIGN", data)
}

const shareDesign = (data: EventData) => {
  track("SHARE_DESIGN", data)
}

const signup = (data: EventData) => {
  track("SIGNUP", data)
}

const login = (data: EventData) => {
  track("LOGIN", data)
}

const cloneDesign = (data: CloneDesignEventData) => {
  track("CLONE_DESIGN", data)
}

const initialDesign = (data: EventData) => {
  track("INITIAL_DESIGN", data)
}

const clickStartCreating = (data: EventData) => {
  track("CLICK_START_CREATING", data)
}

const getSessionTime = () => {
  const sessionDateCache = getSessionTimer()
  if (sessionDateCache) {
    return getSecondsDiff(parseInt(sessionDateCache, 10), new Date().getTime())
  } else {
    setSessionTimer(
      new Date()
        .getTime()
        .toString()
    )
    return 0
  }
}

export const events = {
  openPartsList,
  clickBuyParts,
  addPart,
  swapPart,
  saveDesign,
  shareDesign,
  signup,
  login,
  cloneDesign,
  initialDesign,
  clickStartCreating,
  openBuyPartsTab,
}