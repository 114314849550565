import React, { ChangeEvent, useEffect, useState } from "react"
import "rc-slider/assets/index.css"
import styled from "styled-components"
import { roundLength } from "../../../../../../../utils/utils"
import UnitSelect from "../../../UnitSelect"
import { convertCmToIn, convertInToCm } from "../../../../utils/UnitUtils"
import { DisplayInput } from "./DisplayInput"
import BaseSlider from "./BaseSlider"

type Props = {
    min: number,
    max: number,
    step: number,
    handleMouseUp: (e: number) => void,
    handleMouseDown?: (e: number) => void,
    onChange: (value: number, debounce?: boolean, forceImmediateUpdate?: boolean) => void,
    onInputChange?: (value: number) => void,
    getValue: () => number,
    inputNumber: boolean,
    unit: string,
    marks?: boolean,
    inputFilter?: (val: number) => number,
    readonly?: boolean,
    checkSteps?: boolean,
    disabled?: boolean,
    updateUnit?: (unit: string) => void,
    disabledTyping?: boolean,
}

const handleOnChange = (e: number, props: Props, setValue: Function, isFromTyping = true,
    forceImmediateUpdate = false) => {
    const val = roundLength(props.inputFilter
        ? props.inputFilter(e)
        : e)
    setValue(val)

    if (val >= props.min) {
        props.onChange(val, isFromTyping, forceImmediateUpdate)
    }
    return val
}

const handleOnDisplayChange = (
    e: string,
    setDisplayValue: Function,
) => {
    const regex = /^[0-9]*\.?[0-9]*$/
    if (regex.test(e) || e.endsWith(".")) {
        setDisplayValue(e)
    }
}

const handleOnDisplayConfirm = (
    displayValue: number,
    props: Props,
    setValue: Function,
    setDisplayValue: Function
) => {
    if (isNaN(displayValue)) {
        setDisplayValue(props.getValue())
    } else {
        props.handleMouseUp(displayValue)
    }
}



export function NumberSlider(props: Props) {
    const [value, setValue,] = useState(props.getValue())
    const [displayValue, setDisplayValue,] = useState(props.getValue())
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.valueAsNumber
        const isTyping = e.nativeEvent instanceof InputEvent
        handleOnChange(newValue, props, setValue, isTyping, true)
    }

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const newValue = parseFloat(e.currentTarget.value)
            //used this because we want an immediate update
            handleOnChange(newValue, props, setValue, false, true)
            //props.handleMouseUp(newValue)
        } else if (props.disabledTyping) {
            e.preventDefault()
        }
    }

    useEffect(() => {
        setDisplayValue(value)
    }, [props.unit,])


    const onBlur = () => {
        if (value < props.min) {
            setValue(props.getValue())
        }
        props.onChange(props.getValue())
    }

    useEffect(() => {
        setValue(props.getValue())
    }, [props.getValue(),])

    const getPortal = () => {
        return (
            <NumberSliderContainer>
                <NumberContainer>
                    <div style={{ position: "relative", }}>
                        <input
                            readOnly={props.readonly || false}
                            type={"number"}
                            min={props.min}
                            max={roundLength(props.max)}
                            step={props.step}
                            value={value}
                            onChange={onChange}
                            onKeyDown={props.disabledTyping ? undefined : handleOnKeyDown}
                            onBlur={onBlur}
                            disabled={props.disabled}
                            style={{
                                width: "60px",
                                //color: props.unit === "cm" ? "transparent" : undefined,
                                cursor: props.disabledTyping ? "default" : undefined,
                                caretColor: props.disabledTyping ? "transparent" : undefined,
                            }}
                        />
                    </div>
                    <p>
                        {props.updateUnit ? <UnitSelect
                            updateUnit={props.updateUnit || (() => { })}
                            unit={props.unit}
                        /> : props.unit}

                    </p>
                </NumberContainer>
                <BaseSlider
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    handleMouseUp={props.handleMouseUp}
                    handleMouseDown={props.handleMouseDown}
                    onChange={props.onChange}
                    getValue={props.getValue}
                    marks={props.marks}
                    checkSteps={props.checkSteps}
                    disabled={props.disabled}
                />
            </NumberSliderContainer>
        )
    }
    return getPortal()
}

const NumberSliderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
    padding: 19px 0;
    padding-bottom: 20px;
`

const NumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    p {
        margin: 0;
    }
    input {
        border-radius: 6px;
        border: 1px solid black;
    }
`

export default NumberSlider
export type SliderProps = Props