import React from "react"
import { notification, Spin } from "antd"
import type { NotificationArgsProps } from "antd"

interface MessageConfig {
  duration?: number;
  showCloseIcon?: boolean;
  forceShow?: boolean;
  persistAcrossSessions?: boolean;
  key?: string;
  showSpinner?: boolean;
}

const defaultConfig: MessageConfig = {
    duration: 1,
    showCloseIcon: false,
    forceShow: false,
    persistAcrossSessions: true,
    showSpinner: false,
}

class MessageTracker {
  private shownMessages: Set<string>
  private storageKey = "shownMessages"
  private useLocalStorage: boolean

  constructor() {
    this.useLocalStorage = this.isLocalStorageAvailable()
    this.shownMessages = new Set(this.getStoredMessages())
  }

  private isLocalStorageAvailable(): boolean {
    try {
      localStorage.setItem("test", "test")
      localStorage.removeItem("test")
      return true
    } catch (e) {
      return false
    }
  }

  private getStoredMessages(): string[] {
    if (this.useLocalStorage) {
      const stored = localStorage.getItem(this.storageKey)
      return stored ? JSON.parse(stored) : []
    }
    return []
  }

  private updateStorage() {
    if (this.useLocalStorage) {
      try {
        localStorage.setItem(this.storageKey, JSON.stringify(Array.from(this.shownMessages)))
      } catch (e) {
        console.warn("Failed to update localStorage. Storage might be full.")
      }
    }
  }

  hasShown(key: string): boolean {
    return this.shownMessages.has(key)
  }

  markAsShown(key: string, persist: boolean) {
    this.shownMessages.add(key)
    if (persist && this.useLocalStorage) {
      this.updateStorage()
    }
  }
}

const messageTracker = new MessageTracker()

const showMessage = (
    message: string,
    config: MessageConfig = {}
  ) => {
    const mergedConfig = { ...defaultConfig, ...config, }
    const messageKey = mergedConfig.key || message

    if (!mergedConfig.forceShow && messageTracker.hasShown(messageKey)) {
      return
    }

    messageTracker.markAsShown(messageKey, mergedConfig.persistAcrossSessions || false)

  const notificationConfig: NotificationArgsProps = {
    message: (
      <div>
        {mergedConfig.showSpinner && (
          <Spin size="small" style={{ marginRight: "8px", }} />
        )}
        {message}
      </div>
    ),
    description: "",
    placement: "bottomRight",
    duration: mergedConfig.duration,
    closeIcon: mergedConfig.showCloseIcon ? <></> : null,
    className: "custom-notification",
    style: {
      background: "transparent",
      padding: "8px 12px",
    },
  }

  notification.open(notificationConfig)
}

// Predefined messages
const messages = {
    partAdded: (partName: string, config?: MessageConfig) =>
      showMessage(`Part "${partName}" added to design`, {
        ...config,
        key: "partAdded",
      }),
    designSaved: (config?: MessageConfig) =>
      showMessage("Design saved successfully", {
        ...config,
        key: "designSaved",
      }),
    errorOccurred: (config?: MessageConfig) =>
      showMessage("An error occurred. Please try again.", {
        ...config,
        key: "errorOccurred",
      }),
    // Add more predefined messages as needed
  }

// Custom message function
const customMessage = (text: string, config?: MessageConfig) => {
    showMessage(text, config)
  }


export const messageUtils = {
  ...messages,
  custom: customMessage,
}