/* eslint-disable complexity */
//eslint-disable-next-line max-lines-per-function

import React from "react"
import {
    Vector3,
} from "three"
import Measurement from "./Measurement"
import { DragPoint, FreePositions } from "../../../../../../../../../providers/moveProvider/types"
import useMeasurements from "./useMeasurements"
import { SegmentedTubeMarkers } from "../../../segmentedTube/types/types"
import { MeshStrategy } from "../../../../../../../../../providers/moveProvider/meshHelpers"
import Decimal from "decimal.js"
import { convertInToCm } from "../../../../../../utils/UnitUtils"
import { UnitType } from "../../../../../../../../../state/scene/types"

interface DragMeasurementsProps {
    center: Vector3;
    pointsArray: FreePositions[];
    strategy: MeshStrategy;
    surfaceStartLength: number;
    surfaceEndLength: number;
    targetMiddleLength: number;
    slideSide: SegmentedTubeMarkers;
    slidingPartLength?: number;
    slidePartUnits?: UnitType;
}

const DragMeasurements = (props: DragMeasurementsProps) => {
    const {
        center,
        pointsArray,
        strategy,
        surfaceStartLength,
        surfaceEndLength,
        targetMiddleLength,
        slideSide,
        slidingPartLength,
        slidePartUnits,
    } = props
    const measurementProps = {
        center: center,
        pointsArray: pointsArray,
        strategy: strategy,
        surfaceStartLength: surfaceStartLength,
        surfaceEndLength: surfaceEndLength,
        targetMiddleLength: targetMiddleLength,
        slideSide: slideSide,
        slidingPartLength: slidingPartLength,
        slidePartUnits: slidePartUnits,
    }
    const { distances, group, isSingleMesh, } = useMeasurements(measurementProps)

    const roundToFifth = (num: number) => {
        const d = new Decimal(num)
        const rounded = d.toDecimalPlaces(5, Decimal.ROUND_DOWN)
        const sixthDecimal = d.minus(rounded).times(1000000)
            .floor()
            .toNumber()
        return sixthDecimal === 5 ? rounded.toNumber() : d.toDecimalPlaces(5).toNumber()
    }

    //console.log("props.trueLength", props.slidingPartLength)

    //console.log(slideSide, "slideSide")

    let trueLength = 0

    let trueWidthInCM = slidePartUnits === "cm" ? props.slidingPartLength
        ?? 0 : convertInToCm(props.slidingPartLength ?? 0)

    if (trueWidthInCM !== 0) {
        trueWidthInCM = trueWidthInCM / 100
    }

    //console.log("trueWidthInCM", trueWidthInCM)

    let upRounded = roundToFifth(distances.up ?? 0)
    let downRounded = roundToFifth(distances.down ?? 0)
    let leftRounded = roundToFifth(distances.left ?? 0)
    let rightRounded = roundToFifth(distances.right ?? 0)

    if (distances.up) {
        trueLength += distances.up
    }
    if (distances.down) {
        trueLength += distances.down
    }

    if (trueWidthInCM === 0 || Object.keys(slideSide).length === 1) {
        trueWidthInCM = (distances.left ?? 0) + (distances.right ?? 0)
    }

    const roundedTrueWidthInCM = roundToFifth(trueWidthInCM)

    const roundedLengthForUsers = upRounded + downRounded
    const roundedWidthForUsers = leftRounded + rightRounded

    const trueRoundedWidth = roundToFifth(trueWidthInCM)
    const trueRoundedLength = roundToFifth(trueLength)

    /*console.log("length", trueLength)
    console.log("width", trueWidthInCM)
    console.log("roundedLength", trueRoundedLength)
    console.log("roundedWidth", trueRoundedWidth)
    console.log("roundedLengthForUsers", roundedLengthForUsers)
    console.log("roundedWidthForUsers", roundedWidthForUsers)
    console.log("upRounded", upRounded)
    console.log("downRounded", downRounded)
    console.log("leftRounded", leftRounded)
    console.log("rightRounded", rightRounded)*/

    const adjustMeasurements = (trueRounded: number, value1: number, value2: number) => {
        const sum = value1 + value2
        const diff = trueRounded - sum

        if (Math.abs(diff) < 0.0002) {
            // If the difference is very small, adjust only one value
            return [roundToFifth(value1 + diff), value2,]
        }

        // Distribute the difference proportionally
        const ratio = value1 / sum
        const adjusted1 = roundToFifth(value1 + diff * ratio)
        const adjusted2 = roundToFifth(trueRounded - adjusted1)

        return [adjusted1, adjusted2,]
    }

    // Adjust vertical measurements
    if (trueRoundedLength !== upRounded + downRounded) {
        [upRounded, downRounded,]
            = adjustMeasurements(trueRoundedLength, upRounded, downRounded)
    }

    // Adjust horizontal measurements
    if (roundedTrueWidthInCM !== leftRounded + rightRounded) {
        [leftRounded, rightRounded,]
            = adjustMeasurements(roundedTrueWidthInCM, leftRounded, rightRounded)
    }

    /*console.log("Final upRounded + downRounded:", roundToFifth(upRounded + downRounded))
    console.log("Final leftRounded + rightRounded:", roundToFifth(leftRounded + rightRounded))
    console.log("trueRoundedLength:", trueRoundedLength)
    console.log("trueRoundedWidth:", trueRoundedWidth)*/


    return (
        <group rotation={group.rotation} position={group.position}>
            {!isSingleMesh && upRounded > 0 && (
                <Measurement
                    pointA={new Vector3(0, 0, 0)}
                    pointB={new Vector3(0, upRounded, 0)} />
            )}
            {!isSingleMesh && downRounded > 0 && (
                <Measurement
                    pointA={new Vector3(0, 0, 0)}
                    pointB={new Vector3(0, -downRounded, 0)} />
            )}
            {leftRounded > 0 && (
                <Measurement
                    pointA={new Vector3(0, 0, 0)}
                    pointB={new Vector3(-leftRounded, 0, 0)}
                />
            )}
            {rightRounded > 0 && (
                <Measurement
                    pointA={new Vector3(0, 0, 0)}
                    pointB={new Vector3(rightRounded, 0, 0)} />
            )}
        </group>
    )
}

export default DragMeasurements