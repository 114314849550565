import { Quaternion, Vector3 } from "three"
import { getDragMeshForEnds, getPointsArrayForEnds } from "./meshBuilderEnds"
import { getDragMeshForMiddle, getPointsArrayForMiddle } from "./meshBuilderMiddle"
import { SegmentedTubeMarkers }
    from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"

enum MeshStrategy {
    Ends = "ends",
    Middle = "middle",
}

function getMeshStrategy(sides: any): MeshStrategy {
    const firstAndLastSides = getFirstAndLastSides(sides)
    if (sides[firstAndLastSides[0]].hasOwnProperty("mesh")) {
        return MeshStrategy.Ends
    }
    return MeshStrategy.Middle
}

function shouldRestrictFreeRangeMovement(
    sides: SegmentedTubeMarkers | null,
    slidingSides: SegmentedTubeMarkers | undefined,
    boundary: number | null
) {
    if (!slidingSides) {
        return true
    }
    const strategy = getMeshStrategy(sides)
    if (strategy === MeshStrategy.Ends) {
        return false
    }
    if (!boundary) {
        return true
    }

    return false
}

function getBoundary(sides: any) {
    const firstAndLastSides = getFirstAndLastSides(sides)
    const marker = sides[firstAndLastSides[0]].outer
    if (marker.userData.boundary) {
        return marker.userData.boundary
    }
    return null
}

function getFirstAndLastSides(sides: any) {
    const keys = Object.keys(sides).map(Number)
        .sort((a, b) => a - b)
    const firstKey = keys[0]
    const lastKey = keys[keys.length - 1]

    return [firstKey, lastKey,]
}

function getMarkerQuaternion(sides: any) {
    const firstAndLastSides = getFirstAndLastSides(sides)
    const marker = firstAndLastSides.map(key => sides[key].outer)[0]
    return marker.getWorldQuaternion(new Quaternion())
}

function getPointsArray(
    sides: any,
    segmentLength = 0.025,
    extend = false,
    stepDensity = 1,
    pieceLength?: number
) {
    const strategy = getMeshStrategy(sides)
    const getPointsArrayFunction
        = strategy === MeshStrategy.Ends ? getPointsArrayForEnds : getPointsArrayForMiddle

    const markers = getMarkersFromSides(sides)
    return getPointsArrayFunction(markers, segmentLength, extend, stepDensity, pieceLength)
}

function getMarkersFromSides(sides: any) {
    const markers = Object.keys(sides).map(key => {
        const marker = sides[key].outer
        if (sides[key].mesh) {
            // if the marker is a mesh, we don't use outer markers
            sides[key].mesh.userData.pointsMarkerName = marker.name
            return sides[key].mesh
        }
        return marker
    })
    return markers
}

function getDragMesh(sides: any, viewSurfaceMesh = false, viewDragMesh = false) {
    if (!sides) {
        return null
    }
    const strategy = getMeshStrategy(sides)
    if (strategy === MeshStrategy.Ends) {
        return { strategy, ...getDragMeshForEnds(sides, viewSurfaceMesh, viewDragMesh), }
    }
    return {
        strategy, ...getDragMeshForMiddle(sides, viewSurfaceMesh, viewDragMesh),
    }
}

export {
    getDragMesh,
    getPointsArray,
    getFirstAndLastSides,
    MeshStrategy,
    getMarkerQuaternion,
    getBoundary,
    shouldRestrictFreeRangeMovement,
}