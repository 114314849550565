/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import styled from "styled-components"

interface Props {
    dismiss?: boolean;
    labelColor?: string;
    minSize?: boolean;
    selected?: boolean;
    backgroundColor?: string;
    selectedColor?: string;
    hoverColor?: string;
}

const ActionButton = styled.button<Props>`
    background-color: ${(props) => (props.selected
        ? props.selectedColor || "#fafafa"
        : props.backgroundColor || "white")};
    font-family: 'Montserrat', sans-serif;
    border: none;
    transition: all 0.16s ease-in-out;
    padding: ${(props) => (props.minSize ? "0" : "8px")};
    cursor: pointer;
    margin-top: ${(props) => (props.dismiss ? "10px" : "0")};
    height: 100%;
    min-height: ${(props) => (props.minSize ? "0" : "40px")};
    min-width: 20px;
    border-radius: 6px;
    color: ${(props) => (props.selected ? "black" : "#454545")};
    border: 1px solid #d2d3d2;
    font-weight: ${(props) => (props.selected ? "700" : "600")};

    &:hover {
        background-color: ${(props) => props.hoverColor || "#fafafa"};
    }

`

export default ActionButton