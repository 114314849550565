import React, { ReactNode, Suspense, useRef, useEffect } from "react"
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from "recoil"
import DrawCalls from "./DrawCalls"
import { Canvas, useThree, useFrame } from "@react-three/fiber"
import styled from "styled-components"
import Loading from "../../../../../../common/components/Loading"
import { LinearToneMapping, PerspectiveCamera, Vector3 } from "three"
import { useLevaControls } from "../../../../../providers/debugProvider/useLevaControls"

interface Props {
    children?: ReactNode;
}

const Stage: React.FC<Props> = (props) => {
    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
    const { showGlobalAxes, } = useLevaControls()
    return (
        <Suspense fallback={<LoadingScreen><Loading /> Loading Part</LoadingScreen>}>
            <Canvas
                camera={{
                    position: [1, 1, 1,],
                    fov: 10,
                    far: 200,
                }}
                gl={{
                    toneMapping: LinearToneMapping,
                }}
                dpr={window.devicePixelRatio}
                legacy={true}
            >
                {showGlobalAxes && <axesHelper args={[5,]} />}
                <RecoilBridge>
                    <DrawCalls />
                    {props.children}
                </RecoilBridge>
            </Canvas>
        </Suspense>
    )
}

const LoadingScreen = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

export default Stage