import React, { useRef, useState } from "react"
import { Quaternion, useFrame } from "@react-three/fiber"
import { Ring, Line } from "@react-three/drei"
import { Vector3 } from "three"

interface SnapFeedbackAnimationProps {
    position: Vector3;
    rotation: Quaternion;
    color: string;
    animationDuration?: number; // New prop for animation duration in seconds
}

const SnapFeedbackAnimation:
    React.FC<SnapFeedbackAnimationProps> = ({
        position,
        rotation,
        color,
        animationDuration = 1, // Default to 0.5 seconds (original duration)
    }) => {
        const ringRef = useRef<any>()
        const [scale, setScale,] = useState(0.01)
        const [lineProgress, setLineProgress,] = useState(1)

        const lineEnds: [number, number, number][] = [
            [0.05, 0, 0,],
            [-0.05, 0, 0,],
            [0, 0.05, 0,],
            [0, -0.05, 0,],
        ]

        // Define how far from the center the line should stop (e.g., 20% of the total length)
        const stopDistance = 0.3

        useFrame((state, delta) => {
            // Adjust the progress decrement based on the duration
            const progressDecrement = (1 / animationDuration) * delta
            setLineProgress(prev => Math.max(prev - progressDecrement, 0))
        })

        return (
            <group position={position} quaternion={rotation}>
                <Ring
                    ref={ringRef}
                    scale={[scale, scale, scale,]}
                    args={[0.5, 0.1, 32,]} // Inner radius, outer radius, segments
                >
                    <meshBasicMaterial color={color} transparent opacity={0.75} depthTest={false} />
                </Ring>
                {lineEnds.map((end, index) => {
                    const [x, y, z,] = end
                    const startPoint: [number, number, number] = [
                        x * (lineProgress * (1 - stopDistance) + stopDistance),
                        y * (lineProgress * (1 - stopDistance) + stopDistance),
                        z,
                    ]

                    // Calculate arrowhead points
                    const arrowSize = 0.008
                    const arrowDirection = new Vector3(...end).normalize()
                        .negate() // Negate to point inwards
                    const perpendicular
                        = new Vector3(-arrowDirection.y, arrowDirection.x, 0)
                            .multiplyScalar(arrowSize)

                    // Position the arrow just inside the ring
                    const ringRadius = 0.03 // This should match the inner radius of the Ring
                    const arrowBase = new Vector3(...end).normalize()
                        .multiplyScalar(ringRadius)
                    const arrowTip = arrowBase.clone()
                        .add(arrowDirection.multiplyScalar(arrowSize * 2))
                    const arrowPoint1 = new Vector3().addVectors(arrowBase, perpendicular)
                    const arrowPoint2 = new Vector3().addVectors(arrowBase, perpendicular.negate())

                    return (
                        <React.Fragment key={index}>
                            {/* Main line */}
                            <Line
                                points={[startPoint, end,]}
                                color={color}
                                lineWidth={0.002}
                                worldUnits={true}
                                segments={true}
                                dashed={false}
                                opacity={0.5}
                                transparent
                                depthTest={false}
                            />
                            {/* Arrowhead */}
                            <Line
                                points={
                                    [arrowPoint1.toArray(),
                                    arrowTip.toArray(),
                                    arrowPoint2.toArray(),]}
                                color={color}
                                lineWidth={0.002}
                                worldUnits={true}
                                opacity={0.5}
                                transparent
                                depthTest={false}
                            />
                        </React.Fragment>
                    )
                })}
            </group>
        )
    }

export default SnapFeedbackAnimation