/* eslint-disable max-lines-per-function */
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useThree } from "@react-three/fiber"
import useCamera from "../cameraProvider/useCamera"
import {
    ArrowHelper,
    AxesHelper,
    Box3Helper,
    Mesh,
    Quaternion,
    Raycaster,
    BoxGeometry,
    BoxHelper,
    Matrix4,
    MeshBasicMaterial,
    Object3D,
    SphereGeometry,
    Vector3,
} from "three"
import { EventControls } from "../../utils/DragControls"
import SlideText from
    "../../components/main/DesignScreen/scene/part/parts/connector/ui/MoveOnboarding"
import DragMeasurements from
    "../../components/main/DesignScreen/scene/part/parts/connector/ui/measurements/DragMeasurements"
import { captureMessage } from "@sentry/react"
import { useLevaControls } from "../debugProvider/useLevaControls"
import { DragPoint, DragSlideProps, FreePositions } from "./types"
import DrawPoints from "./DrawPoints"
import {
    getBoundary,
    getDragMesh,
    getMarkerQuaternion,
    getPointsArray,
    shouldRestrictFreeRangeMovement,
} from "./meshHelpers"
import { updateCamera } from "./cameraHelpers"
import { SegmentedTubeMarker }
    from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
// eslint-disable-next-line max-len
import MarkerHelpersFreePositions from "../../components/main/DesignScreen/scene/part/parts/utils/markerHelpers/MarkerHelpersFreePositions"
import { useDebugTools } from "../debugProvider/useDebugTools"
import useUnsnap from "./useUnsnap"
import UnsnapIndicator from "./UnsnapIndicator"
import { useGetPart } from "../../state/scene/hooks"
import useUnitConversion from "../../components/main/DesignScreen/utils/UnitUtils"
import { SegmentedTubeValues } from "../../exportables"
import { UnitType } from "../../state/scene/types"

function DragSlide(props: DragSlideProps) {
    const {
        mesh,
        onPositionChange,
        slideSide,
        onDragStart,
        onDragEnd,
        segmentLength,
        draggableObjectCenter,
        startLength,
        endLength,
        slidingSides,
        onNewAttachmentPoint,
        pieceLength,
        boundingBox,
        slidedMarkerPosition,
        getSlidedMarkerPos,
        onUnsnap,
    } = props

    const [centerPosition, setCenterPosition,] = useState<Vector3>(new Vector3())
    const [measurementCenter, setMeasurementCenter,]
        = useState<Vector3>(draggableObjectCenter.position)
    const dragMeshRef = useRef<Mesh>(null)
    const dragMeshSurfaceRef = useRef<Mesh>(null)

    const { scene, gl, } = useThree()
    const {
        getCamera,
        enableCamera,
        disableCamera,
        fitAndLookAtBox,
        saveCameraState,
        resetCameraState,
    } = useCamera()
    const { drawVector3Point, } = useDebugTools()
    const camera = getCamera()
    const domElement = gl.domElement
    const urlParams = new URLSearchParams(window.location.search)
    const forceOnboardingParam = urlParams.get("forceOnboarding")
    const [showMoveOnboarding, setShowMoveOnboarding,] = useState(true)
    const [bubbleCenter, setBubbleCenter,] = useState<Vector3>(new Vector3())
    const [closestPoint, setClosestPoint,] = useState<DragPoint | null>(null)
    const previousPointRef = useRef<FreePositions | null>(null)
    const [slidePartUnits, setSlidePartUnits,] = useState<UnitType | undefined>(undefined)
    const [slidingPartLength, setSlidingPartLength,] = useState<number | undefined>(undefined)

    const getPart = useGetPart()
    const { updateUnit, } = useUnitConversion()

    const {
        useCameraUp,
        viewDragMesh,
        viewSurfaceMesh,
        viewSlidePoints,
        viewBoundingBoxPoints,
        viewSurfacePoints,
        viewHelpers,
        useFit,
        yOffset,
        xOffset,
        zOffset,
        viewIntersectionPoints,
        stepDensity,
    } = useLevaControls()
    const offsets = {
        y: yOffset,
        x: xOffset,
        z: zOffset,
    }
    const eventControlRef = useRef<EventControls | null>(null)

    const unsnapHandler = useCallback((value: Vector3) => {
        enableCamera()
        onUnsnap && onUnsnap({
            position: value,
            meshName: "unsnap",
        })
    }, [onUnsnap,])
    const { isUnsnapping, currentPhase, onPointerMove,
        onPointerUp, unsnapDirection, } = useUnsnap({ unsnapHandler, })

    useEffect(() => {
        centerPosition.copy(draggableObjectCenter.position)
        setCenterPosition(centerPosition)
    }, [
        draggableObjectCenter.position.x,
        draggableObjectCenter.position.y,
        draggableObjectCenter.position.z,
    ])

    useEffect(() => {
        setMeasurementCenter(draggableObjectCenter.position)
    }, [draggableObjectCenter.position,])

    const onNewAttachmentPointHandler = (markerName: string, position?: Vector3) => {
        onNewAttachmentPoint && onNewAttachmentPoint(markerName, position)
    }

    const dragMesh = useMemo(() => {

        const getSlidePart = () => {
            if (slideSide && typeof slideSide === "object") {
                const firstKey = Object.keys(slideSide)[0]
                if (firstKey) {
                    const innerOrOuter = slideSide[firstKey].inner || slideSide[firstKey].outer
                    if (innerOrOuter && innerOrOuter.userData) {
                        return innerOrOuter.userData.partId
                    }
                }
            }
            return undefined
        }
        const dragMesh = getDragMesh(slideSide, viewSurfaceMesh, viewDragMesh)
        const slidingPart = getPart(getSlidePart())
        const slidePartUnits = (slidingPart as SegmentedTubeValues)?.partUnits
        const slidingPartLength = (slidingPart as SegmentedTubeValues)?.unitRealValue

        if (slidingPartLength) {
            setSlidingPartLength(slidingPartLength)
        }

        if (slidePartUnits) {
            updateUnit(slidePartUnits as UnitType)
            setSlidePartUnits(slidePartUnits as UnitType)
        }
        return dragMesh
    }, [slideSide, scene, viewSurfaceMesh, viewDragMesh,])



    useEffect(() => {
        if (!dragMesh) {
            return
        }
        const dragMeshCenter = new Vector3()
        dragMesh.surfaceMesh.geometry.computeBoundingBox()
        dragMesh?.surfaceMesh?.geometry?.boundingBox?.getCenter(dragMeshCenter)

        dragMesh.surfaceMesh.localToWorld(dragMeshCenter)

        if (boundingBox) {
            const boxCenter = new Vector3()
            boundingBox.getCenter(boxCenter)
            const boxMesh = new Mesh(
                new BoxGeometry(
                    boundingBox.max.x - boundingBox.min.x,
                    boundingBox.max.y - boundingBox.min.y,
                    boundingBox.max.z - boundingBox.min.z
                ),
                new MeshBasicMaterial({ visible: false, })
            )
            boxMesh.position.copy(boxCenter)
            // Find the closest point within the bounding box
            const closestPointForBubble = new Vector3(
                Math.max(boundingBox.min.x, Math.min(dragMeshCenter.x, boundingBox.max.x)),
                Math.max(boundingBox.min.y, Math.min(dragMeshCenter.y, boundingBox.max.y)),
                Math.max(boundingBox.min.z, Math.min(dragMeshCenter.z, boundingBox.max.z))
            )
            setBubbleCenter(closestPointForBubble)

            setShowMoveOnboarding(true)
        }
    }, [slideSide, dragMesh,])


    const pointsArray = useMemo(() => {
        if (!slideSide || !dragMesh) {
            return {
                freePositions: [],
                boundingBoxPoints: [],
            }
        }
        const pointsArray = getPointsArray(
            slideSide,
            segmentLength,
            true,
            stepDensity as number,
            pieceLength
        )
        return pointsArray
    }, [dragMesh, segmentLength, stepDensity,])

    const slidingPointsArray = useMemo(() => {
        if (!slidingSides) {
            return []
        }
        const slidingPointsArray = getPointsArray(slidingSides, segmentLength)
        return slidingPointsArray.freePositions
    }, [slidingSides, segmentLength, closestPoint,])

    useEffect(() => {
        saveCameraState()
        if (dragMesh) {
            updateCamera(dragMesh, fitAndLookAtBox,
                scene, slideSide, useCameraUp, viewHelpers, useFit, offsets,
                draggableObjectCenter, boundingBox,
            )
        }
    }, [dragMesh,])

    const getClosestPoint = (value: Vector3) => {
        const newPosition = value
        const closestPoint = pointsArray.freePositions.reduce((previousPoint, currentPoint) => {
            const distanceToNewPositionCurrent = newPosition.distanceTo(currentPoint.position)
            const distanceToNewPositionPrevious = newPosition.distanceTo(previousPoint.position)
            return distanceToNewPositionCurrent < distanceToNewPositionPrevious
                ? currentPoint : previousPoint
        })
        return closestPoint
    }

    let lastClosestPoint: DragPoint | null = null

    const getIntersectingMarkers = (value: Vector3, slidingPointsArray: FreePositions[]) => {
        const raycaster = new Raycaster()
        const worldQuaternion = getMarkerQuaternion(slideSide)
        const perpendicularAxis = new Vector3(0, 0, 1)
        const worldPerpendicularAxis = perpendicularAxis.clone().applyQuaternion(worldQuaternion)
        const direction = worldPerpendicularAxis.clone().negate()
        const localXAxisOffset = new Vector3(0, 0, 0.1).applyQuaternion(worldQuaternion)

        // Add an axis helper to the marker
        // const axisHelper = new AxesHelper(0.5)
        // marker.add(axisHelper)
        const intersectingMarkers = slidingPointsArray.filter((marker) => {
            const offsetPosition = marker.position.clone().add(localXAxisOffset)

            raycaster.set(offsetPosition, direction)

            const intersects
                = dragMesh?.surfaceMesh ? raycaster.intersectObject(dragMesh.surfaceMesh) : []

            // const rayHelper = new ArrowHelper(
            //     direction,
            //     offsetPosition,
            //     10,
            //     0xff0000,
            // )
            // scene.add(rayHelper)
            return intersects.length > 0
        })

        if (viewIntersectionPoints) {
            intersectingMarkers.forEach((marker) => {
                drawVector3Point(marker.position, 0x00ff00, 0.001)
            })
        }
        return intersectingMarkers
    }

    const getMiddleIntersectingMarker = (value: Vector3, color?: string) => {
        // TODO: optimize this so it doesn't create the points everytime
        const slidingPointsA = getPointsArray(slidingSides, segmentLength, false)
        const intersectingMarkers = getIntersectingMarkers(value, slidingPointsA.freePositions)

        if (Array.isArray(intersectingMarkers) && intersectingMarkers.length) {
            // Calculate the centroid of the intersecting markers
            const centroid = intersectingMarkers.reduce((acc, marker) => {
                acc.x += marker.position.x
                acc.y += marker.position.y
                acc.z += marker.position.z
                return acc
            }, new Vector3(0, 0, 0)).divideScalar(intersectingMarkers.length)

            // Find the marker closest to the centroid
            const middleMarker = intersectingMarkers.reduce((closest, marker) => {
                const distanceToCentroid = marker.position.distanceTo(centroid)
                const distanceToClosest = closest.position.distanceTo(centroid)
                return distanceToCentroid < distanceToClosest ? marker : closest
            })

            if (viewIntersectionPoints) {
                drawVector3Point(middleMarker.position, color, 0.001)
            }
            return middleMarker
        }
    }

    const onDragStartHandler = () => {
        !forceOnboardingParam && setShowMoveOnboarding(false)
        onDragStart && onDragStart()
    }

    const onDragEndHandler = useCallback((value: Vector3) => {
        onPointerUp()

        const pointToCheck = value || lastClosestPoint
        if (!pointToCheck) {
            captureMessage("Couldn't find point in onDragEndHandler")
            console.warn("Couldn't find point in onDragEndHandler")
        }
        const closest = getClosestPoint(pointToCheck)

        const middleMarker = slidingSides
            ? getMiddleIntersectingMarker(pointToCheck, "green") : undefined
        // props.onNewAttachmentPoint(closest.meshName)
        onDragEnd && closest && onDragEnd(
            { position: pointToCheck, meshName: closest.meshName, },
            middleMarker
        )
        // onPositionChange({
        //     position: closest.position,
        //     meshName: closest.meshName,
        // })
        if (eventControlRef.current && getSlidedMarkerPos) {
            const slidedMarkerPos = getSlidedMarkerPos()
            // once it passes through handleSlideMovement it uses this marker as the "pivot/center"
            eventControlRef.current.setDraggableObjectCenter(slidedMarkerPos)
        }
    }, [onDragEnd, slidingSides, getSlidedMarkerPos,])

    // eslint-disable-next-line max-statements
    const onPositionChangeHandler = useCallback((value: Vector3, intersectsSurface: boolean) => {
        const closestPoint = getClosestPoint(value)
        lastClosestPoint = closestPoint
        let pointToUse = { position: value, meshName: closestPoint.meshName, }
        const restrictFreeRange
            = true
        setClosestPoint(closestPoint)
        if (restrictFreeRange) {
            pointToUse = closestPoint
        }
        onPositionChange(pointToUse)
        if (!slidingSides) {
            // Connectors don't have slidingSides
            onPointerMove(value, intersectsSurface)
            return
        }

        // props.onNewAttachmentPoint(closestPoint.meshName, closestPoint.position)
        const middleMarkerAfterChange = getMiddleIntersectingMarker(value, "red")

        // it won't find a middle marker but also not a previous point, so it will allow movement
        if (middleMarkerAfterChange) {
            setMeasurementCenter(middleMarkerAfterChange.position)
            // console.log("middleMarkerAfterChange", middleMarkerAfterChange)
            previousPointRef.current = closestPoint
            // setMeasurementCenter(middleMarkerAfterChange.position)
            // drawVector3Point(closestPoint.position, 0x00ff00, 0.0012)
            // props.onNewAttachmentPoint(closestPoint.meshName)
            // onPositionChange({
            //     position: closestPoint.position,
            //     meshName: closestPoint.meshName,
            // })
            if (viewIntersectionPoints) {
                drawVector3Point(middleMarkerAfterChange.position, 0xf0f00, 0.001)
            }
        } else if (previousPointRef.current) {
            onPositionChange(previousPointRef.current)
            if (viewIntersectionPoints) {
                drawVector3Point(previousPointRef.current.position, 0xff0000, 0.003)
            }
        }
        onPointerMove(value, !!middleMarkerAfterChange)
    }, [onPositionChange, slidingSides, viewIntersectionPoints, onPointerMove,])

    useEffect(() => {
        if (showMoveOnboarding && !forceOnboardingParam) {
            setTimeout(() => {
                setShowMoveOnboarding(false)
            }, 3000)
        }
    }, [showMoveOnboarding,])

    useEffect(() => {
        const center = slidedMarkerPosition ? slidedMarkerPosition : centerPosition
        const eventControl = new EventControls(
            mesh,
            camera,
            domElement,
            onPositionChangeHandler,
            onDragEndHandler,
            enableCamera,
            disableCamera,
            center,
            scene,
            drawVector3Point,
        )
        if (dragMeshRef.current) {
            eventControl.setPlane(dragMeshRef.current)
        }
        if (dragMeshSurfaceRef.current) {
            eventControl.setSurface(dragMeshSurfaceRef.current)
        }
        eventControl.setDraggable(() => { }, true)
        eventControl.addEventListener("dragstart", onDragStartHandler)
        eventControlRef.current = eventControl

        return () => {
            onDragStart && eventControl.removeEventListener("dragstart", onDragStartHandler)
            eventControl.dispose()
            eventControlRef.current = null
        }
    }, [dragMesh,])

    useEffect(() => {
        if (slidedMarkerPosition) {
            eventControlRef.current?.setDraggableObjectCenter(slidedMarkerPosition)
        }
    }, [slidedMarkerPosition,])

    return (
        <>
            <SlideText hide={!showMoveOnboarding} center={bubbleCenter} />
            {(slideSide && dragMesh) && <DragMeasurements
                slideSide={slideSide}
                center={measurementCenter}
                pointsArray={pointsArray.boundingBoxPoints}
                strategy={dragMesh.strategy}
                surfaceStartLength={startLength}
                surfaceEndLength={endLength}
                targetMiddleLength={segmentLength}
                slidingPartLength={slidingPartLength}
                slidePartUnits={slidePartUnits}
            />}
            {dragMesh && (
                <>
                    <mesh
                        ref={dragMeshRef}
                        geometry={dragMesh.dragMesh.geometry}
                        material={dragMesh.dragMesh.material}
                        position={dragMesh.dragMesh.position}
                        quaternion={dragMesh.dragMesh.quaternion}
                        name="dragMesh"
                    />
                    <mesh
                        ref={dragMeshSurfaceRef}
                        geometry={dragMesh.surfaceMesh.geometry}
                        material={dragMesh.surfaceMesh.material}
                        position={dragMesh.surfaceMesh.position}
                        quaternion={dragMesh.surfaceMesh.quaternion}
                        name="surfaceMesh"
                    />
                </>
            )}
            {viewSlidePoints && (
                <DrawPoints
                    points={pointsArray.freePositions}
                    ignoreDepth={false}
                />)
            }
            {viewBoundingBoxPoints && (
                <DrawPoints
                    points={pointsArray.boundingBoxPoints}
                    ignoreDepth={false}
                />)
            }
            {viewSurfacePoints && (
                <MarkerHelpersFreePositions
                    slideSide={slidingSides}
                    attachmentPoint={draggableObjectCenter}
                    viewInnerMarkers={true}
                    viewOuterMarkers={true}
                    points={slidingPointsArray}
                    onDotClick={onNewAttachmentPointHandler}
                />
            )}
            {isUnsnapping && (
                <UnsnapIndicator
                    isUnsnapping={isUnsnapping}
                    currentPhase={currentPhase}
                    position={closestPoint ? closestPoint.position : new Vector3()}
                    direction={unsnapDirection}
                />
            )}
        </>
    )
}

export default DragSlide