/* eslint-disable max-statements */
import { useState, useCallback, useRef, useEffect } from "react"
import { useThree } from "@react-three/fiber"
import { Vector3, ArrowHelper, Raycaster, Plane } from "three"
import { useDebugTools } from "../debugProvider/useDebugTools"

const PHASE_PERCENTAGES = [0.3, 0.5, 0.7, 1.0,] // Percentages for each phase
const MIN_DISTANCE_TO_EDGE = 0.05 // Minimum distance to consider for scaling

const useUnsnap = ({ unsnapHandler, }: { unsnapHandler: (value: Vector3) => void, }) => {
    const [isUnsnapped, setIsUnsnapped,] = useState(false)
    const [isUnsnapping, setIsUnsnapping,] = useState(false)
    const [currentPhase, setCurrentPhase,] = useState(0)

    const startPointRef = useRef<Vector3 | null>(null)
    const directionRef = useRef<Vector3 | null>(null)
    const { drawVector3Point, } = useDebugTools()
    const hasUnsnappedRef = useRef(false)
    const { scene, camera, } = useThree()
    const arrowHelperRef = useRef<ArrowHelper | null>(null)
    const distanceToEdgeRef = useRef<number | null>(null)

    const unsnap = useCallback((point: Vector3) => {
        if (!hasUnsnappedRef.current) {
            setIsUnsnapped(true)
            hasUnsnappedRef.current = true
            unsnapHandler(point)
        }
    }, [unsnapHandler,])

    const onPointerUp = useCallback(() => {
        setIsUnsnapping(false)
        setCurrentPhase(0)
    }, [])

    const onPointerMove = (point: Vector3, isIntersecting: boolean) => {
        if (hasUnsnappedRef.current) {
            return
        }
        if (isIntersecting) {
            // Reset everything when intersecting again
            setIsUnsnapping(false)
            setCurrentPhase(0)
            startPointRef.current = null
            directionRef.current = null

            // Remove the arrow helper
            if (arrowHelperRef.current) {
                scene.remove(arrowHelperRef.current)
                arrowHelperRef.current = null
            }
            distanceToEdgeRef.current = null
            return
        }

        setIsUnsnapping(true)

        if (!startPointRef.current) {
            startPointRef.current = point.clone()
            return
        }

        if (!directionRef.current) {
            const direction = point.clone().sub(startPointRef.current)
                .normalize()
            directionRef.current = direction

            distanceToEdgeRef.current
                = Math.max(MIN_DISTANCE_TO_EDGE, calculateScreenEdgeDistance()
                    || MIN_DISTANCE_TO_EDGE)
            // Create arrow helper only once
            // if (!arrowHelperRef.current) {
            //     const arrowHelper = new ArrowHelper(
            //         direction,
            //         startPointRef.current,
            //         Math.max(MIN_ARROW_LENGTH, point.distanceTo(startPointRef.current)),
            //         ARROW_COLOR,
            //         ARROW_HEAD_LENGTH,
            //         ARROW_HEAD_WIDTH
            //     )
            //     scene.add(arrowHelper)
            //     arrowHelperRef.current = arrowHelper
            // }
        }

        const distance = point.distanceTo(startPointRef.current)
        const scaledPhaseDistances
            = PHASE_PERCENTAGES
                .map(percentage => percentage * (distanceToEdgeRef.current || MIN_DISTANCE_TO_EDGE))
        const newPhase = scaledPhaseDistances.findIndex(d => distance < d)
        setCurrentPhase(newPhase === -1 ? PHASE_PERCENTAGES.length : newPhase)

        if (newPhase === -1) {
            unsnap(point)
        }
    }

    function calculateScreenEdgeDistance() {
        if (!startPointRef.current || !directionRef.current || !camera) {
            return null
        }

        // Project start point to screen space
        const startScreen
            = new Vector3(startPointRef.current.x, startPointRef.current.y, startPointRef.current.z)
        startScreen.project(camera)

        // Convert to pixel coordinates
        const startX = (startScreen.x + 1) * window.innerWidth / 2
        const startY = (-startScreen.y + 1) * window.innerHeight / 2

        // Get normalized 2D direction
        const dir2D = new Vector3(directionRef.current.x, directionRef.current.y, 0).normalize()

        // Calculate intersection with screen edges
        let distance
        if (Math.abs(dir2D.x) > Math.abs(dir2D.y)) {
            // Moving more horizontally
            const edgeX = dir2D.x > 0 ? window.innerWidth : 0
            distance = Math.abs((edgeX - startX) / dir2D.x)
        } else {
            // Moving more vertically
            const edgeY = dir2D.y > 0 ? 0 : window.innerHeight
            distance = Math.abs((edgeY - startY) / dir2D.y)
        }

        // Convert distance back to world units
        const worldDistance = distance / (window.innerHeight * camera.projectionMatrix.elements[5])

        return worldDistance * 3
    }

    return {
        isUnsnapped: hasUnsnappedRef.current,
        isUnsnapping,
        unsnapDirection: directionRef.current,
        currentPhase,
        onPointerMove,
        onPointerUp,
    }
}

export default useUnsnap