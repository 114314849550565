import React from "react"
import { roundLength } from "../../../../../../../../utils/utils"
import { LengthSliderConfig, SEGMENTED_TUBE_UI } from "../types/types"
import SegmentedTubeLengthSlider from "./SegmentedTubeLengthSlider"

interface Props {
    lengthSliderConfig: LengthSliderConfig & { onValueChange: (value: number) => void, };
    setTubeUI: (ui: SEGMENTED_TUBE_UI) => void;
}

const LengthUI = (props: Props) => {
    const handleFinishEditing = () => {
        props.setTubeUI(SEGMENTED_TUBE_UI.CLOSE)
    }

    const getEdgesLength = () => {
        return props.lengthSliderConfig.startSegmentLength
            + props.lengthSliderConfig.endSegmentLength
    }

    //value that shows in the display
    const getValue = () => {
        if (props.lengthSliderConfig.unitRealValue) {
            return props.lengthSliderConfig.unitRealValue
        }
        else {
            console.warn("no props.lengthSliderConfig.scaledSegmentLength")
            return roundLength(props.lengthSliderConfig.value
                * props.lengthSliderConfig.segmentLength
                + getEdgesLength())
        }
    }

    const getMin = () => {
        return roundLength(
            props.lengthSliderConfig.segmentLength
            + getEdgesLength()
        )
    }

    const getMax = () => {
        return roundLength(props.lengthSliderConfig.validMax
            * props.lengthSliderConfig.segmentLength
            + getEdgesLength())
    }

    const getStep = () => {
        return props.lengthSliderConfig.segmentLength
    }

    const handleMouseUp = (value: number) => {
        props.lengthSliderConfig.setHideAddPartButtons(false)
        if (props.lengthSliderConfig.onMouseUp) {
            props.lengthSliderConfig.onMouseUp(value)
        }

    }

    const handleMouseDown = () => {
        if (props.lengthSliderConfig.onMouseDown) {
            props.lengthSliderConfig.onMouseDown()
        }
    }

    const handleChange = (value: number) => {
        props.lengthSliderConfig.onchange(value)
        props.lengthSliderConfig.onValueChange(value)
    }

    const isProcessing = React.useRef(false)

    const handleChangeFromInput = (value: number) => {
        if (isProcessing.current) {
            return
        }
        isProcessing.current = true
        handleMouseDown()

        setTimeout(() => {
            handleChange(value)
            setTimeout(() => {
                handleMouseUp(value)
                isProcessing.current = false
            }, 25)
        }, 25)
    }


    return (
        <SegmentedTubeLengthSlider
            value={getValue()}
            min={(props.lengthSliderConfig.minMiddles || 1)
                * props.lengthSliderConfig.segmentLength}
            max={(props.lengthSliderConfig.maxMiddles || 80)
                * props.lengthSliderConfig.segmentLength}
            validMin={getMin()}
            validMax={getMax()}
            step={props.lengthSliderConfig.segmentLength}
            unit={props.lengthSliderConfig.unit}
            updateUnit={props.lengthSliderConfig.updateUnit}
            onMouseUp={handleMouseUp}
            onChange={handleChange}
            onInputChange={handleChangeFromInput}
            onMouseDown={handleMouseDown}
            onFinishEditing={handleFinishEditing}
            setDirection={props.lengthSliderConfig.setDirection}
            direction={props.lengthSliderConfig.direction}
        />
    )
}

export default LengthUI