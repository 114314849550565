
import React, { useEffect, useState } from "react"
import { Html } from "@react-three/drei"
import styled, { createGlobalStyle } from "styled-components"
import { ReactComponent as PlusIcon }
  from "../../../common/assets/icons/Plus.svg"

import { ReactComponent as SelectionIcon }
  from "../../../common/assets/icons/Selection.svg"

import { ReactComponent as HandIcon }
  from "../../../common/assets/icons/Hand.svg"

import { ReactComponent as SelectionIconWhite }
  from "../../../common/assets/icons/Selection_White.svg"

import { ReactComponent as HandIconWhite }
  from "../../../common/assets/icons/Hand_White.svg"

import { ReactComponent as GearIcon }
  from "../../../common/assets/icons/Gear.svg"

import { ReactComponent as GearIconWhite }
  from "../../../common/assets/icons/Gear_White.svg"


import ReactDOM from "react-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { addPartModal } from "../../state/atoms"
import { Quaternion, Vector3 } from "three"
import { selectedPartSelector } from "../../state/scene/selectors"

interface Props {
  changeSelectionMode: (isActive: boolean) => void;
  selectionMode: boolean;
  transformMode: "translate" | "rotate" | "scale" | "off";
  changeTransformMode: (mode: "translate" | "rotate" | "scale" | "off") => void;
  onTopOfTransformControls: boolean;
  resetSelection: () => void;
  updateCounter: number;
  setUpdateCounter: React.Dispatch<React.SetStateAction<number>>;
}

const GlobalStyle = createGlobalStyle`
      .buttons-container {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    
      .icon-button {
        width: 40px;
        height: 40px;
        border: none;
        background-color: #fff;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        padding:10px;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 2px rgba(0,0,0,0.2);
        transition: all 0.3s ease;
    
        &:hover {
          background-color: #f0f0f0;
        }
    
        &.active {
          background-color: #000;
        }
        &.right {
        border-radius: 5px 0 0 5px;
        }
        &.left {
        border-radius: 0 5px 5px 0;
        }

      }
       

      .icon-button svg {
        width: 100%;
      }
    
      .tooltip-container {
        position: relative;
      }

    
      .tooltip {
        position: absolute;
        left: 50%;
        bottom: -40px;
        transform: translateX(-50%);
        background-color: #333;
        color: #fff;
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 12px;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 0.3s ease;
        pointer-events: none;
    
        .tooltip-container:hover & {
          opacity: 1;
        }

        &.long {
          left: -76px;
          transform: translateX(0);
        }
      }
    
      .buttons-group {
        display: flex;
        background-color: #fff;
      }
    `

const TopRightButtons: React.FC<Props> = ({ changeSelectionMode, changeTransformMode,
  resetSelection, selectionMode, transformMode, onTopOfTransformControls,
  updateCounter, setUpdateCounter, }) => {
  const [isPanActive, setIsPanActive,] = useState(false)
  const [isHighlightActive, setIsHighlightActive,] = useState(false)
  const [container, setContainer,] = useState<HTMLElement | null>(null)
  const selectedPart = useRecoilValue(selectedPartSelector)
  const [hideSelectionIcon, setHideSelectionIcon,] = useState(false)
  const [hideGearIcon, setHideGearIcon,] = useState(true)


  const setPartModal = useSetRecoilState(addPartModal)

  const handleTapToStart = () => {
    setUpdateCounter(prevState => prevState + 1)
    setPartModal({
      step1: {
        source: undefined,
      },
    })
  }

  useEffect(() => {
    const newContainer = document.createElement("div")
    newContainer.id = "top-right-buttons"
    Object.assign(newContainer.style, {
      position: "fixed",
      top: "0",
      right: "0",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginTop: "55px",
      marginRight: "10px",
      gap: "10px",
      width: "250px",
    })
    document.body.appendChild(newContainer)
    setContainer(newContainer)

    return () => {
      document.body.removeChild(newContainer)
    }
  }, [])

  useEffect(() => {
    if (selectedPart) {
      setHideSelectionIcon(true)
      setHideGearIcon(false)
    }
    else {
      setHideSelectionIcon(false)
      setHideGearIcon(true)
    }
  }, [selectedPart,])


  useEffect(() => {
    setIsHighlightActive(selectionMode)
    if (transformMode === "off" && selectionMode === false) {
      setIsPanActive(true)
    }
    if (transformMode !== "off" && !onTopOfTransformControls) {
      setIsPanActive(true)
    }
    else if (transformMode === "rotate"
      || transformMode === "translate" || selectionMode === true) {
      setIsPanActive(false)
    }

  }, [selectionMode, transformMode, onTopOfTransformControls,])


  const handlePanClick = () => {
    setIsPanActive(true)
    setIsHighlightActive(false)
    changeSelectionMode(false)
  }

  const handleHighlightClick = () => {
    setIsPanActive(false)
    setIsHighlightActive(true)
    changeSelectionMode(true)
    changeTransformMode("off")
  }

  const handleGearClick = () => {
    //setHideGearIcon(!hideGearIcon)
  }

  return (
    <Html wrapperClass={"neutralHTML"}>
      <GlobalStyle />
      {container && ReactDOM.createPortal(
        <div className="buttons-container">
          <div className="tooltip-container">
            <button className="icon-button" onClick={handleTapToStart}>
              <PlusIcon fill="#0467FD" />
            </button>
            <span className="tooltip">Add</span>
          </div>
          <div className="buttons-group">
            <div className="tooltip-container">
              <button className={`icon-button ${isPanActive ? "active" : ""} right`}
                onClick={handlePanClick}>
                {isPanActive ? <HandIconWhite /> : <HandIcon />}
              </button>
              <span className="tooltip">Pan</span>
            </div>
            {!hideSelectionIcon && (
              <div className="tooltip-container">
                <button
                  className={`icon-button ${isHighlightActive ? "active" : ""} left`}
                  onClick={handleHighlightClick}
                >
                  {isHighlightActive ? <SelectionIconWhite /> : <SelectionIcon />}
                </button>
                <span className="tooltip">Highlight</span>
              </div>
            )}
            {!hideGearIcon && (
              <div className="tooltip-container">
                <button
                  className={`icon-button ${"active"} left`}
                  onClick={handleGearClick}
                >
                  <GearIconWhite />
                </button>
                <span className="tooltip long">Snapped Controls</span>
              </div>
            )}
          </div>
        </div>,
        container
      )}
    </Html>
  )
}

export default TopRightButtons

