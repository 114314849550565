import { Scene } from "three"
import { ApiClient } from "../../common/api/ApiClient"
import { ConnectionTypeAPI, SizeAPI } from "../../common/api/Types"
import { getMarkerRef, getMarkerUserData, innerToOuter, outerToInner } from "./MarkerUtil"
import { MeshUtils } from "./MeshUtils"
import { ObjDictionary } from "../../common/utils/utils"

export type SwapType = {
  partToSwapId: string,
  connectionTypesDictionary: ObjDictionary<ConnectionTypeAPI>,
  sizes: ObjDictionary<SizeAPI>,
  connectionATypeId: string,
  connectionASizeId: string,
  maxLength?: number,
  makeVisible: () => void,
}

export const getCompatibleParts = async (
  connectionTypesDictionary: ObjDictionary<ConnectionTypeAPI>,
  sizes: ObjDictionary<SizeAPI>,
  connectionATypeId: string,
  connectionASizeId: string,
  connectionBTypeId?: string,
  connectionBSizeId?: string,
  maxLength?: number,
  userQuery?: any
) => {
  if (connectionBTypeId && connectionBSizeId) {
    let parts = await ApiClient.getSwapParts(
      sizes,
      {
        sizeId: connectionASizeId,
        compatibleConnections: connectionTypesDictionary[connectionATypeId].compatibleWithMulti,
      },
      {
        sizeId: connectionBSizeId,
        compatibleConnections: connectionTypesDictionary[connectionBTypeId].compatibleWithMulti,
      },
      userQuery
    )
    parts = parts.filter(p => {
      return p.connections.some(c =>
        p.connections.some(d => d.placeholderId === `inner${c.oppositeOf}`)
      )
    })
    if (maxLength) {
      parts = parts.filter(p => {
        return (p.connections[0].fullLeg <= maxLength)
      })
    }
    return parts
  } else {
    //console.log("swapping when connected on only one part")
    return await ApiClient.getParts(sizes, {
      sizeId: connectionASizeId,
      compatibleConnections: connectionTypesDictionary[connectionATypeId].compatibleWithMulti,
    }, userQuery)
  }
}

export const getSourceData = (
  partId: string,
  markerName: string,
  scene: Scene,
) => {

  const innerMarkerName = outerToInner(markerName)
  const outerMarkerName = innerToOuter(markerName)

  const innerMarkerRef = getMarkerRef(scene, partId, innerMarkerName)
  const outerMarkerRef = getMarkerRef(scene, partId, outerMarkerName)

  const userData = getMarkerUserData(innerMarkerRef!)

  const innerPos = MeshUtils.copyWorldPosition(innerMarkerRef!)
  const innerRot = MeshUtils.copyWorldQuaternion(innerMarkerRef!)

  const outerPos = MeshUtils.copyWorldPosition(outerMarkerRef)
  const outerRot = MeshUtils.copyWorldQuaternion(outerMarkerRef!)

  return {
    partId: userData.partId,
    markerId: userData.id,
    sizeId: userData.sizeId,
    markerName: userData.markerName,
    posAndRot: {
      inner: { pos: innerPos, rot: innerRot, },
      outer: { pos: outerPos, rot: outerRot, },
    },
    connectionLength: userData.iELength,
    type: userData.partType,
  }
}